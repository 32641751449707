// import React, { Component } from "react";
// import { Header, TopBar, Footer, Loader } from "../layouts/general";
// import { Link } from "react-router-dom";

// class Contact02 extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       headers: [
//         {
//           id: 1,
//           names: "Blog",
//         },
//       ],
//       titleheading: [
//         {
//           id: "1",
//           title: "Contact Us",
//         },
//       ],
//       breadcrumbs: [
//         {
//           id: 1,
//           title: "Home",
//           classicon: "fa fa-angle-right",
//           aria: "true",
//         },
//         {
//           id: 2,
//           title: "Contact",
//           classicon: "fa fa-angle-right",
//           aria: "true",
//         },
//         {
//           id: 3,
//           title: "Contact Us",
//           classicon: "",
//           aria: "",
//         },
//       ],
//       contactinfo: [
//         {
//           id: "1",
//           title: "Address",
//           info: "89 Pademba Road, Freetown, Sierra Leone",
//         },
//         {
//           id: "2",
//           title: "Phone number",
//           info: "Call us: +23279677382 / +23233677382 / +23276801413",
//         },
//         {
//           id: "3",
//           title: "E-mail address",
//           info: "info@gordonassociates-sl.com / lesliegb@gordonassociates-sl.com",
//         },
//       ],
//     };
//   }
//   render() {
//     return (
//       <div className="bg-body">
//         <div className="boxed">
//           <Loader />
//           <TopBar />
//           {this.state.headers.map((data) => (
//             <Header data={data} key={data.id} />
//           ))}

//           <div className="page-title">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-12">
//                   <div className="page-title-heading">
//                     {this.state.titleheading.map((data) => (
//                       <h1 key={data.id} className="h1-title">
//                         {data.title}
//                       </h1>
//                     ))}
//                   </div>
//                   <ul className="breadcrumbs">
//                     {this.state.breadcrumbs.map((data) => (
//                       <li key={data.id}>
//                         <Link to="#" title="">
//                           {data.title}
//                           <i
//                             className={data.classicon}
//                             aria-hidden={data.aria}
//                           ></i>
//                         </Link>
//                       </li>
//                     ))}
//                   </ul>
//                   <div className="clearfix"></div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <section className="flat-row">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-12">
//                   <div className="title-section center s1 pd-title-section">
//                     <h2>GET IN TOUCH WITH US</h2>
//                     <p className="sub-title-section">
//                       We welcome your inquiries and feedback. At Gordon
//                       Associates, we're committed to providing top-notch
//                       services and support. If you're looking to explore our
//                       services, have questions, or need assistance, we're here
//                       to help.
//                     </p>
//                   </div>
//                   <div className="dividers dividers-imagebox v1"></div>
//                 </div>
//               </div>
//             </div>
//           </section>
//           <section className="flat-row pdmap">
//             <iframe
//               src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1192.6762859937086!2d-13.251240035514676!3d8.482226999221502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf1b3e2b9f05f845%3A0x3c534f0e4f19691c!2s89%20Pademba%20Rd%2C%20Freetown%2C%20Sierra%20Leone!5e0!3m2!1sen!2sbd!4v1627206548218"
//               loading="lazy"
//               width="100%"
//               height="454"
//               title="Gordon Associates Location"
//               frameBorder="0"
//               style={{ border: '0', marginTop: '20px' }}
//               allowFullScreen=""
//               aria-hidden="false"
//               tabIndex="0"
//             ></iframe>
//           </section>
//           <section className="flat-row pd-contact-v1">
//             <div className="container">
//               <div className="row">
//                 <div className="col-md-4">
//                   <div className="contact-info">
//                     {this.state.contactinfo.map((data) => (
//                       <div className="info info-address" key={data.id}>
//                         <div className="title">{data.title}</div>
//                         <p>{data.info}</p>
//                       </div>
//                     ))}
//                   </div>
//                 </div>

//                 <div className="col-md-8">
//                   <div className="flat-form-info">
//                     <form
//                       id="contactform"
//                       method="post"
//                       action="./contact/contact-process.php"
//                       noValidate="novalidate"
//                       className="form-info"
//                     >
//                       <div className="one-half v3">
//                         <p className="input-info">
//                           <input
//                             type="text"
//                             name="name"
//                             id="name"
//                             placeholder="Name"
//                             required="required"
//                           />
//                         </p>
//                         <p className="input-info">
//                           <input
//                             type="email"
//                             name="email"
//                             id="email"
//                             placeholder="Email"
//                             required="required"
//                           />
//                         </p>
//                         <p className="input-info">
//                           <input
//                             type="text"
//                             name="subject"
//                             id="subject"
//                             placeholder="Subject"
//                             required="required"
//                           />
//                         </p>
//                         <p className="input-info">
//                           <button type="submit" className="submit">
//                             Send Message
//                           </button>
//                         </p>
//                       </div>
//                       <div className="one-half v4">
//                         <p className="input-info">
//                           <textarea
//                             id="message-contact"
//                             name="message"
//                             placeholder="Message"
//                             required="required"
//                           ></textarea>
//                         </p>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </section>
//           <Footer />
//         </div>
//       </div>
//     );
//   }
// }

// export default Contact02;


////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Header, TopBar, Footer, Loader } from "../layouts/general";
import { Link } from "react-router-dom";

class Contact02 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          names: "Blog",
        },
      ],
      titleheading: [
        {
          id: "1",
          title: "Contact Us",
        },
      ],
      breadcrumbs: [
        {
          id: 1,
          title: "Home",
          classicon: "fa fa-angle-right",
          aria: "true",
        },
        {
          id: 2,
          title: "Contact",
          classicon: "fa fa-angle-right",
          aria: "true",
        },
        {
          id: 3,
          title: "Contact Us",
          classicon: "",
          aria: "",
        },
      ],
      contactinfo: [
        {
          id: "1",
          title: "Address",
          info: "89 Pademba Road, Freetown, Sierra Leone",
        },
        {
          id: "2",
          title: "Phone number",
          info: "Call us: +23279677382 / +23233677382 / +23276801413",
        },
        {
          id: "3",
          title: "E-mail address",
          info: "info@gordonassociates-sl.com / lesliegb@gordonassociates-sl.com",
        },
      ],
    };
  }
  render() {
    return (
      <div className="bg-body">
        <div className="boxed">
          <Loader />
          <TopBar />
          {this.state.headers.map((data) => (
            <Header data={data} key={data.id} />
          ))}

          <div className="page-title">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="page-title-heading">
                    {this.state.titleheading.map((data) => (
                      <h1 key={data.id} className="h1-title">
                        {data.title}
                      </h1>
                    ))}
                  </div>
                  <ul className="breadcrumbs">
                    {this.state.breadcrumbs.map((data) => (
                      <li key={data.id}>
                        <Link to="#" title="">
                          {data.title}
                          <i
                            className={data.classicon}
                            aria-hidden={data.aria}
                          ></i>
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>

          <section className="flat-row">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="title-section center s1 pd-title-section">
                    <h2>GET IN TOUCH WITH US</h2>
                    <p className="sub-title-section">
                      We welcome your inquiries and feedback. At Gordon
                      Associates, we're committed to providing top-notch
                      services and support. If you're looking to explore our
                      services, have questions, or need assistance, we're here
                      to help.
                    </p>
                  </div>
                  <div className="dividers dividers-imagebox v1"></div>
                </div>
              </div>
            </div>
          </section>
          <section className="flat-row pdmap">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1192.6762859937086!2d-13.251240035514676!3d8.482226999221502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf1b3e2b9f05f845%3A0x3c534f0e4f19691c!2s89%20Pademba%20Rd%2C%20Freetown%2C%20Sierra%20Leone!5e0!3m2!1sen!2sbd!4v1627206548218"
              loading="lazy"
              width="100%"
              height="454"
              title="Gordon Associates Location"
              frameBorder="0"
              style={{ border: '0', marginTop: '20px' }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </section>
          <section className="flat-row pd-contact-v1">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <div className="contact-info">
                    {this.state.contactinfo.map((data) => (
                      <div className="info info-address" key={data.id}>
                        <div className="title">{data.title}</div>
                        <p>{data.info}</p>
                      </div>
                    ))}
                  </div>
                </div>

              <div className="col-md-8">
                <div className="flat-form-info">
                  {/* Embed the Mailchimp form here */}
                  <iframe
                    title="Mailchimp Form"
                    src="https://us14.list-manage.com/contact-form?u=39757280e9f7b735e8a7f2498&form_id=7521c21634fd409b5cc2f6e1e14b4fa4"
                    width="100%"
                    height="700"
                    frameBorder="0"
                    //scrolling="no"
                    style={{ border: '0', minHeight: '700px' }}
                  >
                  </iframe>
                </div>
              </div>

                
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Contact02;
