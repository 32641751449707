// import MailchimpSubscribe from 'react-mailchimp-subscribe';
// import NewsletterForm from './NewsletterForm';

// const NewsletterSubscribe = () => {

//   const MAILCHIMP_URL = process.env.NEXT_PUBLIC_MAILCHIMP_URL;

//   return (
//     <MailchimpSubscribe
//       url={ MAILCHIMP_URL }
//       render={ ( props ) => {
//         const { subscribe, status, message } = props || {};
//         return (
//           <NewsletterForm
//             status={ status }
//             message={ message }
//             onValidated={ formData => subscribe( formData ) }
//           />
//         );
//       } }
//     />
//   );
// };

// export default NewsletterSubscribe;

/////////////////////////////////////////////////////

// Import necessary modules
import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import NewsletterForm from './NewsletterForm';

// NewsletterSubscribe component
const NewsletterSubscribe = () => {
  // Mailchimp URL
  //const MAILCHIMP_URL = process.env.NEXT_PUBLIC_MAILCHIMP_URL;

  const MAILCHIMP_URL = 'https://gordonassociates-sl.us14.list-manage.com/subscribe/post?u=39757280e9f7b735e8a7f2498&amp;id=336b91e0d5&amp;f_id=00ffb3e0f0"';

  return (
    <MailchimpSubscribe
      url={MAILCHIMP_URL}
      render={(props) => {
        const { subscribe, status, message } = props || {};
        return (
          <NewsletterForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        );
      }}
    />
  );
};

// Export the component
export default NewsletterSubscribe;
