import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { Header, TopBar, Footer, Loader } from "../layouts/general";

class TaxCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          names: "Pages",
        },
      ],
      dataBanner: [
        {
          id: 1,
          classename01: "flat-title-page parallax parallax7 style3",
          classename02: "container wrap-title-page ",
          links: "/about-us",
          names: "About Us",
        },
      ],
    };
  }

  render() {
    return (

      <div>
      <Loader />
      <TopBar />
      {this.state.headers.map((data) => (
        <Header data={data} key={data.id} />
      ))}
      <main style={{
        backgroundColor: '#F9FAFB', // bg-gray-50
        color: '#1F2937', // text-gray-900
        minHeight: '100vh',
        padding: '3rem 1rem' // py-12 px-4 sm:px-6 lg:px-8
      }}>
        <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
          <h1 style={{ fontSize: '2.25rem', fontWeight: 'bold', marginBottom: '2.5rem', textAlign: 'center' }}>
            Tax Calendar
          </h1>
          <section style={{ marginBottom: '4rem' }}>
            <h2 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '1.5rem' }}>
              First Half of the Year
            </h2>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1.5rem' }}>
              {/* Example for one month */}
              <div style={{
                backgroundColor: '#ffffff', // bg-white
                borderRadius: '0.5rem', // rounded-lg
                padding: '1.5rem', // p-6
                boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
                color: '#1F2937' // text-gray-900 for dark mode
              }}>
                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>January</h3>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Jan: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Jan: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS, PROVISIONAL REVENUE ESTIMATES TO THE NRA, PAYROLL TAX</h3></p>
              </div>
              {/* Repeat similar styling for other months */}
              {/* Example for one month */}
              <div style={{
                backgroundColor: '#ffffff', // bg-white
                borderRadius: '0.5rem', // rounded-lg
                padding: '1.5rem', // p-6
                boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
                color: '#1F2937' // text-gray-900 for dark mode
              }}>
                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>February</h3>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Feb: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>28th Feb: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
              </div>
              {/* Repeat similar styling for other months */}
              {/* Example for one month */}
              <div style={{
                backgroundColor: '#ffffff', // bg-white
                borderRadius: '0.5rem', // rounded-lg
                padding: '1.5rem', // p-6
                boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
                color: '#1F2937' // text-gray-900 for dark mode
              }}>
                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>March</h3>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Mar: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS, 1ST QUARTERLY INSTALLMENT PAYMENTS FOR CIT AND PIT</h3></p>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Mar: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
              </div>
              {/* Repeat similar styling for other months */}

              {/* Example for one month */}
              <div style={{
                backgroundColor: '#ffffff', // bg-white
                borderRadius: '0.5rem', // rounded-lg
                padding: '1.5rem', // p-6
                boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
                color: '#1F2937' // text-gray-900 for dark mode
              }}>
                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>April</h3>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Apr: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>30th Apr: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS, FILING OF INCOME TAX RETURNS & FINANCIAL STATEMENTS FOR 2022</h3></p>
              </div>
              {/* Repeat similar styling for other months */}
              {/* Example for one month */}
              <div style={{
                backgroundColor: '#ffffff', // bg-white
                borderRadius: '0.5rem', // rounded-lg
                padding: '1.5rem', // p-6
                boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
                color: '#1F2937' // text-gray-900 for dark mode
              }}>
                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>May</h3>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th May: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st May: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
              </div>
              {/* Repeat similar styling for other months */}
              {/* Example for one month */}
              <div style={{
                backgroundColor: '#ffffff', // bg-white
                borderRadius: '0.5rem', // rounded-lg
                padding: '1.5rem', // p-6
                boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
                color: '#1F2937' // text-gray-900 for dark mode
              }}>
                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>June</h3>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Jun: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS, 2ND QUARTERLY INSTALLMENT PAYMENTS FOR CIT AND PIT</h3></p>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>30th Jun: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
              </div>
              {/* Repeat similar styling for other months */}
            </div>
          </section>
          {/* Repeat similar structure for the second half of the year */}

          <section style={{ marginBottom: '4rem' }}>
            <h2 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '1.5rem' }}>
              Second Half of the Year
            </h2>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1.5rem' }}>
              {/* Example for one month */}
              <div style={{
                backgroundColor: '#ffffff', // bg-white
                borderRadius: '0.5rem', // rounded-lg
                padding: '1.5rem', // p-6
                boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
                color: '#1F2937' // text-gray-900 for dark mode
              }}>
                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>July</h3>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Jul: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Jul: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
              </div>
              {/* Repeat similar styling for other months */}
              {/* Example for one month */}
              <div style={{
                backgroundColor: '#ffffff', // bg-white
                borderRadius: '0.5rem', // rounded-lg
                padding: '1.5rem', // p-6
                boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
                color: '#1F2937' // text-gray-900 for dark mode
              }}>
                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>August</h3>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Aug: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Aug: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
              </div>
              {/* Repeat similar styling for other months */}
              {/* Example for one month */}
              <div style={{
                backgroundColor: '#ffffff', // bg-white
                borderRadius: '0.5rem', // rounded-lg
                padding: '1.5rem', // p-6
                boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
                color: '#1F2937' // text-gray-900 for dark mode
              }}>
                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>September</h3>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Sep: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS, 3RD QUARTERLY INSTALLMENT PAYMENTS FOR CIT AND PIT</h3></p>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>30th Sep: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
              </div>
              {/* Repeat similar styling for other months */}

              {/* Example for one month */}
              <div style={{
                backgroundColor: '#ffffff', // bg-white
                borderRadius: '0.5rem', // rounded-lg
                padding: '1.5rem', // p-6
                boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
                color: '#1F2937' // text-gray-900 for dark mode
              }}>
                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>October</h3>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Oct: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Oct: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
              </div>
              {/* Repeat similar styling for other months */}
              {/* Example for one month */}
              <div style={{
                backgroundColor: '#ffffff', // bg-white
                borderRadius: '0.5rem', // rounded-lg
                padding: '1.5rem', // p-6
                boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
                color: '#1F2937' // text-gray-900 for dark mode
              }}>
                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>November</h3>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Nov: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>30th Nov: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
              </div>
              {/* Repeat similar styling for other months */}
              {/* Example for one month */}
              <div style={{
                backgroundColor: '#ffffff', // bg-white
                borderRadius: '0.5rem', // rounded-lg
                padding: '1.5rem', // p-6
                boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
                color: '#1F2937' // text-gray-900 for dark mode
              }}>
                <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>December</h3>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Dec: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS, 4TH QUARTERLY INSTALLMENT PAYMENTS FOR CIT AND PIT</h3></p>
                <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Dec: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
              </div>
              {/* Repeat similar styling for other months */}
            </div>
          </section>
        </div>
      </main>
      <Footer/>
      </div>
    );
  }
}

export default TaxCalendar;
/////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////

////Code below is the second trying suggestion phase

// import React, { Component } from "react";
// //import { Link } from "react-router-dom";
// import { Header, TopBar, Footer, Loader } from "../layouts/general";

// class TaxCalendar extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {

//       isMobile: window.innerWidth < 640,

//       headers: [
//         {
//           id: 1,
//           names: "Pages",
//         },
//       ],
//       dataBanner: [
//         {
//           id: 1,
//           classename01: "flat-title-page parallax parallax7 style3",
//           classename02: "container wrap-title-page ",
//           links: "/about-us",
//           names: "About Us",
//         },
//       ],
      
//     };
//   }

//   componentDidMount() {
//     window.addEventListener('resize', this.handleWindowSizeChange);
//   }

//   componentWillUnmount() {
//     window.removeEventListener('resize', this.handleWindowSizeChange);
//   }

//   handleWindowSizeChange = () => {
//     this.setState({ isMobile: window.innerWidth < 640 });
//   }

  

//   render() {

//     const gridStyle = this.state.isMobile ? { display: 'block' } : { display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1.5rem' };

//     const cardStyle = this.state.isMobile ? { width: '100%' } : { width: 'calc(33.33% - 1.5rem)' }; // Adjust the width as needed

//     return (

//       <>
//       <Loader />
//       <TopBar />
//       {this.state.headers.map((data) => (
//         <Header data={data} key={data.id} />
//       ))}
//       <div style={gridStyle}>
//       <main style={{
//         backgroundColor: '#F9FAFB', // bg-gray-50
//         color: '#1F2937', // text-gray-900
//         minHeight: '100vh',
//         padding: '3rem 1rem' // py-12 px-4 sm:px-6 lg:px-8
//       }}>
//         <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
//           <h1 style={{ fontSize: '2.25rem', fontWeight: 'bold', marginBottom: '2.5rem', textAlign: 'center' }}>
//             Tax Calendar
//           </h1>
//           <section style={{ marginBottom: '4rem' }}>
//             <h2 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '1.5rem' }}>
//               First Half of the Year
//             </h2>
//             <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1.5rem' }}>
//               {/* Example for one month */}
//               <div style={{
//                 ...cardStyle,
//                 backgroundColor: '#ffffff', // bg-white
//                 borderRadius: '0.5rem', // rounded-lg
//                 padding: '1.5rem', // p-6
//                 boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
//                 color: '#1F2937' // text-gray-900 for dark mode
//               }}>
//                 <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>January</h3>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Jan: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Jan: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS, PROVISIONAL REVENUE ESTIMATES TO THE NRA, PAYROLL TAX</h3></p>
//               </div>
//               {/* Repeat similar styling for other months */}
//               {/* Example for one month */}
//               <div style={{
//                 ...cardStyle,
//                 backgroundColor: '#ffffff', // bg-white
//                 borderRadius: '0.5rem', // rounded-lg
//                 padding: '1.5rem', // p-6
//                 boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
//                 color: '#1F2937' // text-gray-900 for dark mode
//               }}>
//                 <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>February</h3>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Feb: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>28th Feb: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
//               </div>
//               {/* Repeat similar styling for other months */}
//               {/* Example for one month */}
//               <div style={{
//                 ...cardStyle,
//                 backgroundColor: '#ffffff', // bg-white
//                 borderRadius: '0.5rem', // rounded-lg
//                 padding: '1.5rem', // p-6
//                 boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
//                 color: '#1F2937' // text-gray-900 for dark mode
//               }}>
//                 <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>March</h3>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Mar: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS, 1ST QUARTERLY INSTALLMENT PAYMENTS FOR CIT AND PIT</h3></p>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Mar: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
//               </div>
//               {/* Repeat similar styling for other months */}

//               {/* Example for one month */}
//               <div style={{
//                 ...cardStyle,
//                 backgroundColor: '#ffffff', // bg-white
//                 borderRadius: '0.5rem', // rounded-lg
//                 padding: '1.5rem', // p-6
//                 boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
//                 color: '#1F2937' // text-gray-900 for dark mode
//               }}>
//                 <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>April</h3>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Apr: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>30th Apr: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS, FILING OF INCOME TAX RETURNS & FINANCIAL STATEMENTS FOR 2022</h3></p>
//               </div>
//               {/* Repeat similar styling for other months */}
//               {/* Example for one month */}
//               <div style={{
//                 ...cardStyle,
//                 backgroundColor: '#ffffff', // bg-white
//                 borderRadius: '0.5rem', // rounded-lg
//                 padding: '1.5rem', // p-6
//                 boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
//                 color: '#1F2937' // text-gray-900 for dark mode
//               }}>
//                 <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>May</h3>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th May: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st May: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
//               </div>
//               {/* Repeat similar styling for other months */}
//               {/* Example for one month */}
//               <div style={{
//                 ...cardStyle,
//                 backgroundColor: '#ffffff', // bg-white
//                 borderRadius: '0.5rem', // rounded-lg
//                 padding: '1.5rem', // p-6
//                 boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
//                 color: '#1F2937' // text-gray-900 for dark mode
//               }}>
//                 <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>June</h3>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Jun: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS, 2ND QUARTERLY INSTALLMENT PAYMENTS FOR CIT AND PIT</h3></p>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>30th Jun: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
//               </div>
//               {/* Repeat similar styling for other months */}
//             </div>
//           </section>
//           {/* Repeat similar structure for the second half of the year */}

//           <section style={{ marginBottom: '4rem' }}>
//             <h2 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '1.5rem' }}>
//               Second Half of the Year
//             </h2>
//             <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1.5rem' }}>
//               {/* Example for one month */}
//               <div style={{
//                 ...cardStyle,
//                 backgroundColor: '#ffffff', // bg-white
//                 borderRadius: '0.5rem', // rounded-lg
//                 padding: '1.5rem', // p-6
//                 boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
//                 color: '#1F2937' // text-gray-900 for dark mode
//               }}>
//                 <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>July</h3>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Jul: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Jul: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
//               </div>
//               {/* Repeat similar styling for other months */}
//               {/* Example for one month */}
//               <div style={{
//                 ...cardStyle,
//                 backgroundColor: '#ffffff', // bg-white
//                 borderRadius: '0.5rem', // rounded-lg
//                 padding: '1.5rem', // p-6
//                 boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
//                 color: '#1F2937' // text-gray-900 for dark mode
//               }}>
//                 <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>August</h3>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Aug: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Aug: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
//               </div>
//               {/* Repeat similar styling for other months */}
//               {/* Example for one month */}
//               <div style={{
//                 ...cardStyle,
//                 backgroundColor: '#ffffff', // bg-white
//                 borderRadius: '0.5rem', // rounded-lg
//                 padding: '1.5rem', // p-6
//                 boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
//                 color: '#1F2937' // text-gray-900 for dark mode
//               }}>
//                 <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>September</h3>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Sep: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS, 3RD QUARTERLY INSTALLMENT PAYMENTS FOR CIT AND PIT</h3></p>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>30th Sep: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
//               </div>
//               {/* Repeat similar styling for other months */}

//               {/* Example for one month */}
//               <div style={{
//                 ...cardStyle,
//                 backgroundColor: '#ffffff', // bg-white
//                 borderRadius: '0.5rem', // rounded-lg
//                 padding: '1.5rem', // p-6
//                 boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
//                 color: '#1F2937' // text-gray-900 for dark mode
//               }}>
//                 <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>October</h3>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Oct: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Oct: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
//               </div>
//               {/* Repeat similar styling for other months */}
//               {/* Example for one month */}
//               <div style={{
//                 ...cardStyle,
//                 backgroundColor: '#ffffff', // bg-white
//                 borderRadius: '0.5rem', // rounded-lg
//                 padding: '1.5rem', // p-6
//                 boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
//                 color: '#1F2937' // text-gray-900 for dark mode
//               }}>
//                 <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>November</h3>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Nov: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</h3></p>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>30th Nov: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
//               </div>
//               {/* Repeat similar styling for other months */}
//               {/* Example for one month */}
//               <div style={{
//                 ...cardStyle,
//                 backgroundColor: '#ffffff', // bg-white
//                 borderRadius: '0.5rem', // rounded-lg
//                 padding: '1.5rem', // p-6
//                 boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)', // Tailwind equivalent to shadow-lg
//                 color: '#1F2937' // text-gray-900 for dark mode
//               }}>
//                 <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>December</h3>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Dec: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS, 4TH QUARTERLY INSTALLMENT PAYMENTS FOR CIT AND PIT</h3></p>
//                 <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Dec: <h3 style={{ fontSize: '0.75rem', fontWeight: 'bold', marginBottom: '0.5rem', color: '#1F2937' }}>GST RETURNS</h3></p>
//               </div>
//               {/* Repeat similar styling for other months */}
//             </div>
//           </section>
//         </div>
//       </main>
//       </div>
//       <Footer/>
//       </>
//     );
//   }
// }

// export default TaxCalendar;

///////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

// import React, { Component } from "react";
// import { Header, TopBar, Footer, Loader } from "../layouts/general";

// class TaxCalendar extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isMobile: window.innerWidth < 640,
//       headers: [{ id: 1, names: "Pages" }],
//       dataBanner: [
//         {
//           id: 1,
//           classename01: "flat-title-page parallax parallax7 style3",
//           classename02: "container wrap-title-page ",
//           links: "/about-us",
//           names: "About Us",
//         },
//       ],
//     };
//   }

//   componentDidMount() {
//     window.addEventListener('resize', this.handleWindowSizeChange);
//   }

//   componentWillUnmount() {
//     window.removeEventListener('resize', this.handleWindowSizeChange);
//   }

//   handleWindowSizeChange = () => {
//     this.setState({ isMobile: window.innerWidth < 640 });
//   }

//   render() {
//     const gridStyle = this.state.isMobile ? { display: 'block', padding: '1rem' } : { display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1.5rem', padding: '3rem 1rem' };

//     const cardStyle = this.state.isMobile ? { width: '100%', marginBottom: '1rem' } : { width: 'calc(33.33% - 1.5rem)', marginBottom: '1rem' };

//     const desktopStyles = `
//       @media (min-width: 641px) {
//         .card-container {
//           display: grid;
//           grid-template-columns: repeat(3, minmax(0, 1fr));
//           gap: 1.5rem;
//         }
//       }

//       .card {
//         background-color: #ffffff;
//         border-radius: 0.5rem;
//         padding: 1.5rem;
//         box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
//         color: #1F2937;
//         box-sizing: border-box;
//         width: 100%;
//         margin-bottom: 1rem;
//       }
//     `;

//     return (
//       <>
//         <Loader />
//         <TopBar />
//         {this.state.headers.map((data) => (
//           <Header data={data} key={data.id} />
//         ))}
//         <div>
//           <main style={{
//             backgroundColor: '#F9FAFB',
//             color: '#1F2937',
//             minHeight: '100vh',
//             padding: this.state.isMobile ? '1rem' : '3rem 1rem',
//           }}>
//             <style>{desktopStyles}</style>
//             <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
//               <h1 style={{ fontSize: '2.25rem', fontWeight: 'bold', marginBottom: '2.5rem', textAlign: 'center' }}>
//                 Tax Calendar
//               </h1>
//               <section style={{ marginBottom: '4rem' }}>
//                 <h2 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '1.5rem' }}>
//                   First Half of the Year
//                 </h2>
//                 <div className="card-container">
//                   <div className="card" style={cardStyle}>
//                     <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>January</h3>
//                     <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Jan: <span style={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</span></p>
//                     <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Jan: <span style={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#1F2937' }}>GST RETURNS, PROVISIONAL REVENUE ESTIMATES TO THE NRA, PAYROLL TAX</span></p>
//                   </div>
//                   {/* Repeat similar styling for other months */}
//                   <div className="card" style={cardStyle}>
//                     <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>February</h3>
//                     <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Feb: <span style={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS</span></p>
//                     <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>28th Feb: <span style={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#1F2937' }}>GST RETURNS</span></p>
//                   </div>
//                   {/* Repeat similar styling for other months */}
//                   <div className="card" style={cardStyle}>
//                     <h3 style={{ fontSize: '1.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>March</h3>
//                     <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>15th Mar: <span style={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#1F2937' }}>PAYE TAX, WHT, SOCIAL SECURITY CONTRIBUTIONS, 1ST QUARTERLY INSTALLMENT PAYMENTS FOR CIT AND PIT</span></p>
//                     <p style={{ fontSize: '1.0rem', fontWeight: 'bold', color: 'red' }}>31st Mar: <span style={{ fontSize: '0.75rem', fontWeight: 'bold', color: '#1F2937' }}>GST RETURNS</span></p>
//                   </div>
//                 </div>
//               </section>
//               <section style={{ marginBottom: '4rem' }}>
//                 <h2 style={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: '1.5rem' }}>
//                   Second Half of the Year
//                 </h2>
//                 <div className="card-container">
//                   {/* ... (rest of your code) */}
//                 </div>
//               </section>
//             </div>
//           </main>
//         </div>
//         <Footer />
//       </>
//     );
//   }
// }

// export default TaxCalendar;




