import React, { Component } from "react";
import BottomBar from "./BottomBar";
import {
  UsefulLink,
  //RecentImage,
  NewsletterSubscribe,
  TopFooter,
  //TextMainFooter,
} from "./footers/index";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <div>
        <footer id="footer">
          <div className="footer-widgets">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <TopFooter />
                </div>
                {/* <!-- /.col-md-12 --> */}
              </div>
              {/* <!-- /.row --> */}
              <div className="row widget-box">
                <div className="col-md-4">
                  {/* <TextMainFooter /> */}
                  <section className="flat-row pdmap">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1192.6762859937086!2d-13.251240035514676!3d8.482226999221502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xf1b3e2b9f05f845%3A0x3c534f0e4f19691c!2s89%20Pademba%20Rd%2C%20Freetown%2C%20Sierra%20Leone!5e0!3m2!1sen!2sbd!4v1627206548218"
                      loading="lazy"
                      width="100%"
                      height="454"
                      title="Gordon Associates Location"
                      frameBorder="0"
                      style={{ border: '0', marginTop: '20px' }}
                      allowFullScreen=""
                      aria-hidden="false"
                      tabIndex="0"
                    >
                    </iframe>
                  </section>
                </div>
                {/* <!-- /.col-md-4 --> */}
                <div className="col-md-4">
                  <UsefulLink />
                </div>
                {/* <!-- /.col-md-4 --> */}
                <div className="col-md-4">
                  {/* <RecentImage /> */}
                  <NewsletterSubscribe/>
                </div>
                {/* <!-- /.col-md-4 --> */}
              </div>
              {/* <!-- /.row .widget-box --> */}
            </div>
            {/* <!-- /.container --> */}
          </div>
          {/* <!-- /.footer-widgets --> */}
          <BottomBar />
          {/* footer-bottom  */}
        </footer>
        <div className="button-go-top">
          <Link to="#" title="" className="go-top">
            <i className="fa fa-chevron-up"></i>
          </Link>
        </div>
      </div>
      // <!-- /#footer -->
    );
  }
}

export default Footer;
