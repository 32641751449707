// import React, { Component } from "react";
// class BBoxView extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       imgslide: [
//         {
//           id: "1",
//           srcimg: "images/about/s01.jpg",
//         },
//         {
//           id: "2",
//           srcimg: "images/about/s01.jpg",
//         },
//       ],
//       content: [
//         {
//           id: "1",
//           title: "BUSINESS ADVISORY",
//           description:
//             "Gordon & Associates works closely with clients to understand their unique challenges and develop tailored strategies for growth, optimization, and overall business success.",
//         },
//       ],
//       blogtimeline: [
//         {
//           id: "1",
//           year: "2012",
//           title: "Market Analysis",
//           description: `We help businesses minimize risks, optimize their resources, and seize growth opportunities. We work
//             closely with clients to customize their approach and recommendations based on the industry, business
//             goals, and market dynamics. By leveraging data-driven insights and expert knowledge, businesses can
//             make well-informed decisions that lead to successful market entry, expansion, and sustained profitability.`,
//         },
//         {
//           id: "2",
//           year: "2013",
//           title: "Research and Innovation",
//           description: `Gordon &amp; Associates has a specialist Research and Innovation team. On the research side, we are a
//           trusted research implementation and evaluation partner. On the innovation side, we are customer/user-led
//           and sustainability obsessed. We can help you define your ambition, refocus your innovation pipeline, and
//           adopt an approach that achieves scale, impact, and return on investment. With over 10+ years’ experience
//           across the innovation spectrum, we partner with you to translate innovation into breakthrough results and
//           real-world impact. Our Research and Innovation consulting helps you stay two steps ahead of the
//           disruption with an innovation strategy and operating model aligned with your goals and design to deliver
//           high-impact results and sustainable solutions.`,
//         },
//         {
//           id: "3",
//           year: "2014",
//           title: "HR Advisory",
//           description: `Gordon & Associates extends a comprehensive range of HR strategic guidance and support, assisting
//             organizations seeking to optimize their human resources and create a positive and productive work
//             environment. Our HR advisory team collaborates with businesses to address HR challenges, enhance
//             processes, and align strategies with overarching business objectives.`,
//         },
//         {
//           id: "4",
//           year: "2014",
//           title: "Environmental, Social, and Governance (ESG) Advisory",
//           description: `Gordon &amp; Associates specializes in ESG Advisory services to businesses, helping businesses develop and
//           execute strategies to proficiently manage their ESG impacts, risks, and opportunities. We assist in
//           integrating sustainability practices, ethics, and responsible governance into daily operations. Our services
//           include evaluating and reporting on sustainability initiatives.
//           \n\n
//           For Environment, we focus on sustainability, resource conservation, and environmental risks.
//           \n
//           In the Social realm, we address community engagement, diversity, inclusion, and employee well-being.
//           \n
//           In the area of Governance, we enhance corporate governance practices, ensuring transparency, accountability, and ethical decision-making for our clients.`,
//         },
//         {
//           id: "5",
//           year: "2014",
//           title: "Accounting and Bookkeeping",
//           description: `We provide expert guidance and assistance to businesses and organizations in managing their financial
//           records, transactions, and reporting. Our suite of services is designed to help businesses maintain accurate
//           and organized financial records, comply with financial regulations, make informed decisions, and optimize
//           their financial processes.`,
//         },
//         {
//           id: "6",
//           year: "2014",
//           title: "Government Affairs and Advocacy",
//           description: `At Gordon & Associates, we offer strategic counsel and assistance to businesses in effectively managing
//           their relationship with government bodies, regulatory agencies and stakeholders. Our expertise lies in
//           aiding clients understand and navigate the regulatory landscape, build positive relationships with
//           policymakers, and effectively advocate for their interests. Through collaborative efforts, we tailor strategies
//           to clients’ specific needs and goals, contributing to business success and sustainability within a complex
//           and ever-evolving environment.`,
//         },
//       ],
//     };
//   }
//   render() {
//     return (
//       <div>
//         {/* <div className="flexslider s2">
//           <div className="flat-slides">
//             <ul className="slides">
//               {this.state.imgslide.map((data) => (
//                 <li key={data.id}>
//                   <img src={data.srcimg} alt="img" />
//                 </li>
//               ))}
//             </ul>
//           </div>
//         </div> */}
//         {this.state.content.map((data) => (
//           <div className="box-content" key={data.id}>
//             <div className="title">{data.title}</div>
//             <p>{data.description}</p>
//             <div className="dividers dividers-bc-v4"></div>
//           </div>
//         ))}

//         {this.state.blogtimeline.map((data) => (
//           <div className="flat-text-block-timeline" key={data.id}>
//             {/* <div className="year">{data.year}</div> */}
//             <div className="flat-timeline-content">
//               <div className="box-content">
//                 <div className="title">{data.title}</div>
//                 <p>{data.description}</p>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     );
//   }
// }

// export default BBoxView;
///////////////////////////////////////////////////////////////////
import React, { Component } from "react";
class TBoxView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgslide: [
        {
          id: "1",
          srcimg: "images/about/s01.jpg",
        },
        {
          id: "2",
          srcimg: "images/about/s01.jpg",
        },
      ],
      content: [
        {
          id: "1",
          title: "TAX ADVISORY",
          description: `We deliver expert tax advisory services to guide individuals and businesses through the complex world of
            taxation, optimizing tax strategies. Our team of tax professionals stay up-to-date with the latest tax laws
            and regulations, offering invaluable insights and personalized guidance to minimize tax liabilities while
            ensuring compliance. Additionally, we offer comprehensive tax support and compliance services, assisting
            individuals and businesses in efficiently fulfilling their tax obligations accurately while optimizing their tax
            position.
            \n
            We provide a comprehensive suite of tax services which includes:`,
        },
      ],
      blogtimeline: [
        {
          id: "1",
          year: "2013",
          title: "Tax Planning and Efficiency",
          description: `Gordon & Associates specializes in Tax Planning, Tax Efficiency, and Tax Advisory services. Our team
          assists clients in strategic planning to reduce tax liabilities and enhance savings. We develop strategies to optimize tax efficiency for both businesses and individuals. Our experts provide valuable advice on a wide
          range of tax-related matters and their implications.`,
        },
        {
          id: "2",
          year: "2014",
          title: "Corporate Tax Services",
          description: `We offer Corporate Tax Services to our clients, showcasing our expertise in managing tax affairs for
          corporations and ensuring full compliance with all relevant regulations.`,
        },
        {
          id: "3",
          year: "2014",
          title: "Small Business Tax Services",
          description: `We provide customized tax solutions designed for small businesses, aimed at ensuring both compliance
          and cost savings.`,
        },
        {
          id: "4",
          year: "2014",
          title: "International Tax Services",
          description: `Gordon & Associates provides International Tax Services, offering valuable guidance on cross-border
          taxation matters for businesses operating on a global scale.`,
        },
        {
          id: "5",
          year: "2014",
          title: "Tax Dispute Resolutions",
          description: `Gordon &a Associates provides Tax Dispute Resolution services to our clients. We offer effective support for
          resolving tax-related disputes with relevant authorities, including the NRA, the Ministry of Finance, and
          other regulatory bodies.`,
        },
        {
          id: "6",
          year: "2014",
          title: "Tax Training and Seminars",
          description: `Gordon & Associates offers Tax Training and Seminars, providing educational services to keep our clients
          well-informed about tax regulations and any updates or changes.`,
        },
      ],
    };
  }

  renderDescription(description) {
    const paragraphs = description.split("\n\n");
    return paragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>);
  }

  render() {
    return (
      <div>
        {this.state.content.map((data) => (
          <div className="box-content" key={data.id}>
            <div className="title">{data.title}</div>
            <p>{data.description}</p>
            <div className="dividers dividers-bc-v4"></div>
          </div>
        ))}

        {this.state.blogtimeline.map((data, index) => (
          <div
            className="flat-text-block-timeline"
            key={data.id}
            style={{
              display: "flex",
              flexDirection: index % 2 === 0 ? "row" : "row-reverse",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "50px",
            }}
          >
            <div
              className="flat-timeline-content"
              style={{
                flexBasis: "100%",
                //textAlign: "center", // Center the text on mobile devices
              }}
            >
              <div className="box-content">
                <div className="title">{data.title}</div>
                {this.renderDescription(data.description)}
              </div>
            </div>

            <div
              className="flat-timeline-img"
              style={{
                flexBasis: "45%",
              }}
              ref={(el) => {
                if (el && window.innerWidth >= 768) {
                  el.style.display = "block";
                } else {
                  el.style.display = "none";
                }
              }}
            >
              <img
                src={`images/services/tax/${data.id}.png`}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        ))}

        
      </div>
    );
  }
}

export default TBoxView;

//Josepg
