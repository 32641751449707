import React, { Component } from "react";
import { Header, TopBar, Footer, Loader } from "../layouts/general";

class Publications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataBanner: [
        {
          id: 1,
          classename01: "flat-title-page parallax parallax7 style3",
          classename02: "container wrap-title-page",
          names: "Publications",
        },
      ],
      selectedPublication: null,
    };

    this.publicationData = [
      {
        name: 'G&A - Finance Act 2024',
        link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/publication%2FGA_Finance%20Act%2024.pdf?alt=media&token=348805a0-c992-40a1-a00f-e436c8ed0c67',
      },
      // You can add more publications as needed
    ];
  }

  render() {
    const { selectedPublication } = this.state;
    return (
      <div>
        <Loader />
        <TopBar />
        {this.state.dataBanner.map((data) => (
          <Header data={data} key={data.id} />
        ))}

        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-bold mb-8 text-center">Publications</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {this.publicationData.map((publication, index) => (
                <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                  <button
                    className="w-full font-medium text-lg"
                    style={{
                      color: selectedPublication === index ? 'black' : 'black',
                      border: `1px solid ${selectedPublication === index ? 'gray' : 'gray'}`,
                      backgroundColor: `${selectedPublication === index ? 'gray' : 'white'}`,
                      padding: '12px',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      textAlign: 'center',
                      lineHeight: '1.5',
                      transition: 'background-color 0.3s',
                    }}
                    onClick={() => this.setState({ selectedPublication: index })}
                  >
                    {publication.name}
                  </button>
                  {selectedPublication === index && (
                    <div className="mt-2">
                      <iframe
                        title={publication.name}
                        src={publication.link}
                        width="100%"
                        height="500px"
                        frameBorder="0"
                      ></iframe>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Publications;
