// import React, { Component } from "react";
// import { Link } from "react-router-dom";

// class MainGridTeam extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       titlegird: [
//         {
//           id: 1,
//           title: "Gordon & Associates",
//           description: `GORDON AND ASSOCIATES (SL) LIMITED, known as Gordon & Associates, stands as a distinguished advisory firm, boasting a team of seasoned consultants from diverse industries. Our expertise spans the private, public, and non-governmental sectors, positioning us as the ideal partner for a wide range of clients. What sets us apart is our profound understanding of the intricate landscapes in which we operate, enabling us to deliver cost-effective and impactful solutions.`,
//         },
//       ],
//       boxgrid: [
//         {
//           id: "1",
//           srcimg: "images/about/lesliePP.png",
//           subtitle: "CHIEF EXECUTIVE OFFICER AND CO-FOUNDER",
//           title: "Leslie Gordon-Browne",
//           description: `Leslie, a seasoned professional in business advisory, boasting a rich background across Sierra Leone, various African countries, and the UK. Leslie excels in formulating and executing initiatives to achieve strategic, operational, and corporate goals. His expertise spans strategic planning, talent acquisition, client engagement, HR advisory, policy architecture, risk mitigation, and regulatory adherence. Leslie's extensive experience positions him as a valuable asset, ready to provide insightful counsel and contribute to our commitment to delivering unparalleled advisory services.`,
//           listsocial: [
//             {
//               id: "1",
//               datatitle: "Facebook",
//               classtitle: "facebook",
//               classicon: "fa fa-facebook",
//             },
//             {
//               id: "2",
//               datatitle: "LinkedIn",
//               classtitle: "linkedin",
//               classicon: "fa fa-linkedin",
//             },
//             {
//               id: "3",
//               datatitle: "Google Plus",
//               classtitle: "google-plus",
//               classicon: "fa fa-google-plus",
//             },
//           ],
//         },
//         {
//           id: "2",
//           srcimg: "images/about/zynaPP.png",
//           subtitle: "DIRECTOR AND CO-FOUNDER",
//           title: "Zyna Gordon-Browne",
//           description: `Zyna, is a seasoned chartered and certified accountant specializing in providing expert business advice. Her focus includes accounting, finance, auditing, taxation, and compliance. Zyna delivers timely and reliable services to a diverse range of corporate clients, offering independent recommendations and strategic guidance. Clients appreciate her technical knowledge, problem-solving skills, and ability to simplify complex concepts. Zyna's clear communication, integrity, and work ethic make her a trusted advisor, consistently relied upon for quality business assurance and advice. She finds satisfaction in guiding clients to positive outcomes, contributing to their success with enthusiasm and expertise.`,
//         },
//         {
//           id: "3",
//           srcimg: "images/about/LydiaPP.png",
//           subtitle: "SENIOR HUMAN RESOURCES AND TRAINING ADVISOR",
//           title: "Lydia Harrison",
//           description: `Lydia is an experienced HR professional specializing in consulting, with a strong background in international organizations, INGOs, the UN, and public/private sectors. Engaging hands-on, she establishes foundations through research, crafting practical strategic plans, addressing HR matters, managing change, integrating policies, and ensuring compliance. Known for commitment and passion, Lydia excels in employee engagement, complex relations, recruitment, staff development, and change management. Her quality work, influential recommendations, and strong stakeholder relationships reflect excellent communication, listening skills, integrity, and empathy.`
//         },
//         {
//           id: "4",
//           srcimg: "images/about/saioPP.png",
//           subtitle: "SENIOR TAX AND ACCOUNTING ADVISOR",
//           title: "Saio Yanka",
//           description: `Saio is a highly qualified professional with extensive experience in technical accounting, taxation, finance, auditing, procurement, and budgeting. Known for providing precise and timely reports, he excels in multitasking and effective time management. His proficiency extends to mining taxation and accounting, including auditing large and small-scale enterprises. Saio's diverse skill set includes expertise in oil and gas taxation, clearing and forwarding business operations, project management, and facilitating the incorporation of businesses and acquisition of work and resident permits for foreign nationals. His broad experience reflects his ability to navigate complex administrative procedures with ease.`
//         },
//         {
//             id: "5",
//             srcimg: "images/about/TahirPP.png",
//             subtitle: "RESEARCH, INNOVATION AND TRAINING ADVISOR",
//             title: "Tahir Bockarie",
//             description: `Tahir, a distinguished Public Health Scientist and Innovation Expert, brings over a decade of global experience in Research and Innovation project management, specializing in digital technology, medical technologies, and artificial intelligence. With a strong track record, he excels in project leadership, proposal crafting, and health and innovation evaluations. Leveraging UK and Sub-Saharan African experience, Tahir played a pivotal role in developing, piloting, and evaluating digital, medical, and AI solutions, including substantial NHS AI projects. His expertise spans strategy development, impact assessment, evidence reviews, and learning and development initiatives.`
//           },

//       ],
//     };
//   }
//   render() {
//     return (
//       <section className="flat-row pd-about-team">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-12">
//               {this.state.titlegird.map((data) => (
//                 <div className="title-section center s1" key={data.id}>
//                   <h2>{data.title}</h2>
//                   <p className="sub-title-section">
//                     GORDON AND ASSOCIATES (SL) LIMITED, known as Gordon &
//                     Associates, stands as a distinguished advisory firm,
//                     boasting a team of seasoned consultants from diverse
//                     industries. Our expertise spans the private, public, and
//                     non-governmental sectors, positioning us as the ideal
//                     partner for a wide range of clients. What sets us apart is
//                     our profound understanding of the intricate landscapes in
//                     which we operate, enabling us to deliver cost-effective and
//                     impactful solutions.
//                   </p>
//                 </div>
//               ))}
//               <div className="dividers dividers-about-team"></div>
//             </div>
//           </div>

//           <div className="row">
//             {this.state.boxgrid.map((data) => (
//               <div className="col-md-3" key={data.id}>
//                 <div className="flat-team team-grid has-image">
//                   <div className="team-image">
//                     <img src={data.srcimg} alt="img" />
//                   </div>
//                   <div className="team-info">
//                     <div className="team-subtitle">{data.subtitle}</div>
//                     <div className="team-name">{data.title}</div>
//                     <div className="team-desc">{data.description}</div>
//                     <div className="social-links">
//                       <Link to="#" data-title="LinkedIn" className="linkedin">
//                         <i className="fa fa-linkedin"></i>
//                       </Link>

//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </section>
//       //   flat-row-iconbox
//     );
//   }
// }

// export default MainGridTeam;
////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////

import React, { Component } from "react";
//import { Link } from "react-router-dom";

class MainGridTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titlegird: [
        {
          id: 1,
          title: "Gordon & Associates",
          description: `GORDON AND ASSOCIATES (SL) LIMITED, known as Gordon & Associates, stands as a distinguished advisory firm, boasting a team of seasoned consultants from diverse industries. Our expertise spans the private, public, and non-governmental sectors, positioning us as the ideal partner for a wide range of clients. What sets us apart is our profound understanding of the intricate landscapes in which we operate, enabling us to deliver cost-effective and impactful solutions.`,
        },
      ],
      boxgrid: [
        {
          id: "1",
          srcimg: "images/about/lesliePP.png",
          subtitle: "CHIEF EXECUTIVE OFFICER AND CO-FOUNDER",
          title: "Leslie Gordon-Browne",
          description: `Leslie, a seasoned professional in business advisory, boasting a rich background across Sierra Leone, various African countries, and the UK. Leslie excels in formulating and executing initiatives to achieve strategic, operational, and corporate goals. His expertise spans strategic planning, talent acquisition, client engagement, HR advisory, policy architecture, risk mitigation, and regulatory adherence. Leslie's extensive experience positions him as a valuable asset, ready to provide insightful counsel and contribute to our commitment to delivering unparalleled advisory services.`,
          listsocial: [
            {
              id: "1",
              datatitle: "Facebook",
              classtitle: "facebook",
              classicon: "fa fa-facebook",
            },
            {
              id: "2",
              datatitle: "LinkedIn",
              classtitle: "linkedin",
              classicon: "fa fa-linkedin",
            },
            {
              id: "3",
              datatitle: "Google Plus",
              classtitle: "google-plus",
              classicon: "fa fa-google-plus",
            },
          ],
        },
        {
          id: "2",
          srcimg: "images/about/zynaPP.png",
          subtitle: "DIRECTOR AND CO-FOUNDER",
          title: "Zyna Gordon-Browne",
          description: `Zyna, is a seasoned chartered and certified accountant specializing in providing expert business advice. Her focus includes accounting, finance, auditing, taxation, and compliance. Zyna delivers timely and reliable services to a diverse range of corporate clients, offering independent recommendations and strategic guidance. Clients appreciate her technical knowledge, problem-solving skills, and ability to simplify complex concepts. Zyna's clear communication, integrity, and work ethic make her a trusted advisor, consistently relied upon for quality business assurance and advice. She finds satisfaction in guiding clients to positive outcomes, contributing to their success with enthusiasm and expertise.`,
        },
        {
          id: "3",
          srcimg: "images/about/LydiaPP.png",
          subtitle: "SENIOR HUMAN RESOURCES AND TRAINING ADVISOR",
          title: "Lydia Harrison",
          description: `Lydia is an experienced HR professional specializing in consulting, with a strong background in international organizations, INGOs, the UN, and public/private sectors. Engaging hands-on, she establishes foundations through research, crafting practical strategic plans, addressing HR matters, managing change, integrating policies, and ensuring compliance. Known for commitment and passion, Lydia excels in employee engagement, complex relations, recruitment, staff development, and change management. Her quality work, influential recommendations, and strong stakeholder relationships reflect excellent communication, listening skills, integrity, and empathy.`
        },
        {
          id: "4",
          srcimg: "images/about/saioPP.png",
          subtitle: "SENIOR TAX AND ACCOUNTING ADVISOR",
          title: "Saio Yanka",
          description: `Saio is a highly qualified professional with extensive experience in technical accounting, taxation, finance, auditing, procurement, and budgeting. Known for providing precise and timely reports, he excels in multitasking and effective time management. His proficiency extends to mining taxation and accounting, including auditing large and small-scale enterprises. Saio's diverse skill set includes expertise in oil and gas taxation, clearing and forwarding business operations, project management, and facilitating the incorporation of businesses and acquisition of work and resident permits for foreign nationals. His broad experience reflects his ability to navigate complex administrative procedures with ease.`
        },
        {
            id: "5",
            srcimg: "images/about/TahirPP.png",
            subtitle: "RESEARCH, INNOVATION AND TRAINING ADVISOR",
            title: "Tahir Bockarie",
            description: `Tahir, a distinguished Public Health Scientist and Innovation Expert, brings over a decade of global experience in Research and Innovation project management, specializing in digital technology, medical technologies, and artificial intelligence. With a strong track record, he excels in project leadership, proposal crafting, and health and innovation evaluations. Leveraging UK and Sub-Saharan African experience, Tahir played a pivotal role in developing, piloting, and evaluating digital, medical, and AI solutions, including substantial NHS AI projects. His expertise spans strategy development, impact assessment, evidence reviews, and learning and development initiatives.`
          },

      ],
    };
  }
  render() {
    return (
      <section className="flat-row pd-about-team">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {this.state.titlegird.map((data) => (
                <div className="title-section center s1" key={data.id}>
                  {/* <h2>{data.title}</h2> */}
                  <p className="sub-title-section">
                  Meet Our Dynamic Team of Seasoned Professionals at Gordon & Associates, where advisory and consulting expertise converge seamlessly. Our accomplished team brings a wealth of experience from the private sector, public sector, and non-governmental sector. With a diverse range of backgrounds and a shared commitment to excellence, we collaboratively navigate challenges and deliver innovative solutions. Get to know the faces behind our success, as we blend industry insights and hands-on experience to drive meaningful impact for our clients. At Gordon & Associates, we're not just consultants; we're trusted partners in your journey towards success.

                  </p>
                </div>
              ))}
              <div className="dividers dividers-about-team"></div>
            </div>
          </div>

          {/* Render Leslie, Zyna, and Lydia on the top row */}
          <div className="row">
            {this.state.boxgrid.slice(0, 3).map((data) => (
              <div className="col-md-4" key={data.id}>
                {/* ... (previous code) */}
                <div className="flat-team team-grid has-image">
                  <div className="team-image">
                    <img src={data.srcimg} alt="img" />
                  </div>
                  <div className="team-info">
                    <div className="team-subtitle">{data.subtitle}</div>
                    <div className="team-name">{data.title}</div>
                    <div className="team-desc">{data.description}</div>
                    {/* <div className="social-links">
                      <Link to="#" data-title="LinkedIn" className="linkedin">
                        <i className="fa fa-linkedin"></i>
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Render Saio and Tahir on the bottom row */}
          <div className="row">
            {this.state.boxgrid.slice(3).map((data) => (
              <div className="col-md-4" key={data.id}>
                {/* ... (previous code) */}
                <div className="flat-team team-grid has-image">
                  <div className="team-image">
                    <img src={data.srcimg} alt="img" />
                  </div>
                  <div className="team-info">
                    <div className="team-subtitle">{data.subtitle}</div>
                    <div className="team-name">{data.title}</div>
                    <div className="team-desc">{data.description}</div>
                    {/* <div className="social-links">
                      <Link to="#" data-title="LinkedIn" className="linkedin">
                        <i className="fa fa-linkedin"></i>
                      </Link>

                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>

  
        </div>
      </section>
      //   flat-row-iconbox
    );
  }
}

export default MainGridTeam;