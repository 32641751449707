// import { useState } from 'react';
// import { sanitize } from '../../../utils/miscellaneous';
// import Loading from '../../loading';

// const NewsletterForm = ( { status, message, onValidated }) => {

//   const [ error, setError ] = useState(null);
//   const [ email, setEmail ] = useState(null);

//   /**
//    * Handle form submit.
//    *
//    * @return {{value}|*|boolean|null}
//    */
//   const handleFormSubmit = () => {

//     setError(null);

//     if ( ! email ) {
//       setError( 'Please enter a valid email address' );
//       return null;
//     }

//     const isFormValidated = onValidated({ EMAIL: email });

//     // On success return true
//     return email && email.indexOf("@") > -1 && isFormValidated;
//   }

//   /**
//    * Handle Input Key Event.
//    *
//    * @param event
//    */
//   const handleInputKeyEvent = ( event ) => {
//     setError(null);
//     // Number 13 is the "Enter" key on the keyboard
//     if (event.keyCode === 13) {
//       // Cancel the default action, if needed
//       event.preventDefault();
//       // Trigger the button element with a click
//       handleFormSubmit();
//     }
//   }

//   /**
//    * Extract message from string.
//    *
//    * @param {String} message
//    * @return {null|*}
//    */
//   const getMessage = (message) => {
//     if ( !message ) {
//       return null;
//     }
//     const result = message?.split('-') ?? null;
//     if ( "0" !== result?.[0]?.trim() ) {
//       return sanitize(message);
//     }
//     const formattedMessage = result?.[1]?.trim() ?? null;
//     return formattedMessage ? sanitize( formattedMessage ) : null;
//   }

//   return (
//     <div>
//       <h3 className="mb-1 uppercase font-bold">Subscribe to newsletter</h3>
//       <div className="flex newsletter-input-fields">
//         <div className="mc-field-group">
//           <input
//             onChange={(event) => setEmail(event?.target?.value ?? '')}
//             type="email"
//             placeholder="Your email"
//             className="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-4 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
//             onKeyUp={(event) => handleInputKeyEvent(event)}
//           />
//         </div>
//         <div className="button-wrap wp-block-button">
//           <button className="cursor-pointer	text-white bg-indigo-500 border-0 py-2 px-5 focus:outline-none hover:bg-indigo-600 rounded" onClick={handleFormSubmit}>
//             Submit
//           </button>
//         </div>
//       </div>
//       <div className="min-h-42px">
//         { 'sending' === status ? <Loading showSpinner message="Sending..." contentColorClass="text-white" hasVisibilityToggle={false}/> : null }
//         {'error' === status || error ? (
//           <div
//             className="text-red-700 pt-2"
//             dangerouslySetInnerHTML={{ __html: error || getMessage( message ) }}
//           />
//         ) : null }
//         {'success' === status && 'error' !== status && !error && (
//           <div className="text-green-200 font-bold pt-2" dangerouslySetInnerHTML={{ __html: sanitize(message) }} />
//         )}
//       </div>
//     </div>
//   );
// }

// export default NewsletterForm
//////////////////////////////////////////////////////////////////////////

// Import necessary modules
import React, { useState } from 'react';
import { sanitize } from './miscellaneous';
import Loading from './Loading';

const NewsletterForm = ({ status, message, onValidated }) => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);

  // Function to handle form submission
  const handleFormSubmit = () => {
    setError(null);

    if (!email) {
      setError('Please enter a valid email address');
      return null;
    }

    const isFormValidated = onValidated({ EMAIL: email });

    return email && email.indexOf('@') > -1 && isFormValidated;
  };

  // Function to handle input key event
  const handleInputKeyEvent = (event) => {
    setError(null);
    if (event.keyCode === 13) {
      event.preventDefault();
      handleFormSubmit();
    }
  };

  // Function to extract message from string
  const getMessage = (message) => {
    if (!message) {
      return null;
    }
    const result = message?.split('-') ?? null;
    if ('0' !== result?.[0]?.trim()) {
      return sanitize(message);
    }
    const formattedMessage = result?.[1]?.trim() ?? null;
    return formattedMessage ? sanitize(formattedMessage) : null;
  };

  return (
    <div className="widget widget-subscribe">
      {/* Form element */}
      <form id="subscribe-form" action="#" method="post" acceptCharset="utf-8" data-mailchimp="true">
        {/* Email input */}
        <div id="subscribe-content">
          <label htmlFor="form-email-footer">Subscribe to our Newsletter:</label>
          <div className="input-email">
            <input
              onChange={(event) => setEmail(event?.target?.value ?? '')}
              type="email"
              placeholder="Your email address"
              // Inline styles for now
              style={{
                padding: '0.5rem',
                borderRadius: '0.25rem',
                border: '1px solid #ccc',
                marginBottom: '0.5rem',
              }}
              onKeyUp={(event) => handleInputKeyEvent(event)}
            />
          </div>
          {/* Submit button */}
          <div className="button-wrap wp-block-button">
            <button
              type="button"
              id="subscribe-button"
              className="button-subscribe"
              // Inline styles for now
              style={{
                cursor: 'pointer',
                backgroundColor: '#00cc66',
                color: '#fff',
                padding: '0.5rem 1rem',
                borderRadius: '0.25rem',
                border: 'none',
                outline: 'none',
              }}
              onClick={handleFormSubmit}
            >
              SUBMIT
            </button>
          </div>
        </div>
        {/* Status messages */}
        <div id="subscribe-msg" className="min-h-42px">
          {status === 'sending' ? (
            <Loading showSpinner message="Sending..." contentColorClass="text-white" hasVisibilityToggle={false} />
          ) : null}
          {status === 'error' || error ? (
            <div
              className="text-red-700 pt-2"
              dangerouslySetInnerHTML={{ __html: error || getMessage(message) }}
            />
          ) : null}
          {status === 'success' && 'error' !== status && !error && (
            <div className="text-green-200 font-bold pt-2" dangerouslySetInnerHTML={{ __html: sanitize(message) }} />
          )}
        </div>
      </form>
    </div>
  );
};

// Export the component
export default NewsletterForm;

