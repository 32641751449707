import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class TopFooter extends Component {
    render() {
        return (
            <div className="widget-infomation">
                <ul className="infomation-footer">
                    <li><i className="fa fa-envelope" aria-hidden="true"></i><Link to="#" title="">info@gordonassociates-sl.com</Link></li>
                    <li><i className="fa fa-phone" aria-hidden="true"></i><Link to="#" title="">+23279677382 / +23233677382 </Link></li>
                    <li><i className="fa fa-map-marker" aria-hidden="true"></i><Link to="#" title="">89 Pademba Road, Freetown, Sierra Leone</Link></li>
                </ul>
                {/* <!-- /.infomation-footer --> */}
            </div>
        /* <!-- /.widget-infomation --> */
        );
    }
}

export default TopFooter;