// import React, { Component } from "react";
// import { Link } from "react-router-dom";
// class EventBoxs extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       dataEvents: [
//         {
//           id: 1,
//           srcimg: "images/imagebox/dalleB.png",
//           title: "Business Advisory",
//           description:
//             "Expert growth and optimization strategies tailored to your business needs",
//         },
//         {
//           id: 2,
//           srcimg: "images/imagebox/dalleT.png",
//           title: "Tax Advisory",
//           description:
//             "Comprehensive tax solutions to ensure compliance and maximize savings",
//         },
//         {
//           id: 3,
//           srcimg: "images/imagebox/daller.png",
//           title: "Risk Advisory",
//           description:
//             "Strategic risk management to protect and strengthen your business",
//         },
//       ],
//     };
//   }

//   render() {
//     return (
//       <div className="row">
//         {this.state.dataEvents.map((data) => (
//           <div className="col-md-4" key={data.id}>
//             <div className="imagebox-item">
//               <div className="imagebox style1">
//                 <div className="imagebox-image">
//                   <img src={data.srcimg} alt="financial" />
//                 </div>
//                 {/* <!-- /.imagebox-image --> */}

//                 <div className="imagebox-title">
//                   {/* <h3><Link to="/blog" onClick={() => {window.location.href="/blog"}} title="">{data.title}</Link></h3> */}
//                   <h3>
//                     <Link
//                       to="/about-v3"
//                       onClick={() => {
//                         window.location.href = "/about-v3";
//                       }}
//                       title=""
//                     >
//                       {data.title}
//                     </Link>
//                   </h3>
//                 </div>
//                 {/* <!-- /.iamgebox-title --> */}
//                 <div className="imagebox-content">
//                   <div className="imagebox-desc">{data.description}</div>
//                   <div className="imagebox-button">
//                     {/* <Link to="/blog" onClick={() => {window.location.href="/blog"}} title="">read more</Link> */}
//                     <Link
//                       to="/about-v3"
//                       onClick={() => {
//                         window.location.href = "/about-v3";
//                       }}
//                       title=""
//                     >
//                       read more
//                     </Link>
//                   </div>
//                 </div>
//                 {/* <!-- /.imagebox-content --> */}
//               </div>
//               {/* <!-- /.imagebox --> */}
//             </div>
//             {/* <!-- /.imagebox-item --> */}
//           </div>
//         ))}
//       </div>
//     );
//   }
// }

// export default EventBoxs;

/////////////////////////////////////////////////////////////////////////
import React, { Component } from "react";
import { Link } from "react-router-dom";
class EventBoxs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataEvents: [
        {
          id: 1,
          srcimg: "images/imagebox/dalleB.png",
          title: "Business Advisory",
          description:
            "Expert growth and optimization strategies tailored to your business needs",
        },
        {
          id: 2,
          srcimg: "images/imagebox/dalleT.png",
          title: "Tax Advisory",
          description:
            "Comprehensive tax solutions to ensure compliance and maximize savings",
        },
        {
          id: 3,
          srcimg: "images/imagebox/daller.png",
          title: "Risk Advisory",
          description:
            "Strategic risk management to protect and strengthen your business",
        },
      ],
    };
  }

  render() {
    return (
      <div className="row">
        {this.state.dataEvents.map((data) => {
          // Create a slug from the title
          const slug = data.title.toLowerCase().replace(/\s+/g, "-");

          return (
            <div className="col-md-4" key={data.id}>
              <div className="imagebox-item">
                <div className="imagebox style1">
                  <div className="imagebox-image">
                    <img src={data.srcimg} alt="financial" />
                  </div>

                  <div className="imagebox-title">
                    <h3>
                      <Link to={`${slug}`} title="">
                        {data.title}
                      </Link>
                    </h3>
                  </div>

                  <div className="imagebox-content">
                    <div className="imagebox-desc">{data.description}</div>
                    <div className="imagebox-button">
                      <Link to={`${slug}`} title="">
                        read more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default EventBoxs;
