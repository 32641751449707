import React, { Component } from 'react';

class TextMainFooter extends Component {
    render() {
        return (
            <div className="widget widget-text">
                <p>GORDON AND ASSOCIATES (SL) LIMITED, known as Gordon &amp; Associates, stands as a distinguished
                    advisory firm, boasting a team of seasoned consultants from diverse industries. Our expertise spans the
                    private, public, and non-governmental sectors, positioning us as the ideal partner for a wide range of
                    clients. What sets us apart is our profound understanding of the intricate landscapes in which we operate,
                    enabling us to deliver cost-effective and impactful solutions.
                </p>
                {/* <p>
                    Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in
                    voluptate 
                </p>	 */}
            </div>
            /* <!-- /.widget-text --> */
        );
    }
}

export default TextMainFooter;