import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  Footer,
  Header,
  Slider,
  Loader,
  //CarouselClient,
  TopBar,
} from "../layouts/general";
import { Featured } from "../layouts/general/featured";
//import { Services } from '../layouts/general/services';
//import { Project } from '../layouts/general/projects';
//import { Callback } from "../layouts/general/callback";
//import { Blog } from "../layouts/general/blog";

import { MainGrid } from "../layouts/aboutus";

//import { Contact02 } from "../layouts/general/contact";

//import { Contact02 } from "../pages/index";

class Home01 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: [
        {
          id: 1,
          names: "Home",
        },
      ],
    };
  }
  render() {
    return (
      <div className="bg-body3">
        <div className="boxed">
          <Loader />
          <TopBar />
          {this.state.headers.map((data) => (
            <Header data={data} key={data.id} />
          ))}
          <MainGrid />
          <Slider />
          <Featured />
          {/* <Services /> */}
          {/* <Project /> */}
          {/* <Callback /> */}
          {/* <Contact02 /> */}

          {/* <Blog /> */}

          {/* <CarouselClient /> */}

          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(Home01);
