// import React, { Component } from 'react';
// import {Link} from 'react-router-dom'
// class BottomBar extends Component {
//     render() {
//         return (
//             <div className="footer-bottom">
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-12">
//                                 <div className="copyright">
//                                     <p>Copyright 2021 Finance. Theme by <Link to="#" title="">Themesflat</Link></p>
//                                 </div>
//                                 <ul className="menu-footer">
//                                     <li><Link to="/" onClick={() => {window.location.href="/"}} title="">Home</Link></li>
//                                     <li><Link to="/blog" onClick={() => {window.location.href="/blog"}} title="">My Blog</Link></li>
//                                     <li><Link to="/" onClick={() => {window.location.href="/"}} title="">My Home</Link></li>
//                                     <li><Link to="#" title="">Sample Page</Link></li>
//                                 </ul>
//                                 {/* <!-- /.menu-footer --> */}
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 /* <!-- /.footer-bottom --> */
//         );
//     }
// }

// export default BottomBar;
///////////////////////////////////////////////////////////////////////////////////

import React, { Component } from 'react';
//import {Link} from 'react-router-dom'
class BottomBar extends Component {
    render() {
        return (
            <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="copyright">
                                    <p>Copyright 2024 GORDON AND ASSOCIATES (SL) LIMITED</p>
                                </div>
                                <ul className="menu-footer">
                                    {/* <li><Link to="/" onClick={() => {window.location.href="/"}} title="">Home</Link></li>
                                    <li><Link to="/blog" onClick={() => {window.location.href="/blog"}} title="">My Blog</Link></li>
                                    <li><Link to="/" onClick={() => {window.location.href="/"}} title="">My Home</Link></li>
                                    <li><Link to="#" title="">Sample Page</Link></li> */}
                                  <li>
                                    <a href="https://www.linkedin.com/company/gordon-associates-sl-ltd/" target="_blank" rel="noopener noreferrer" className="linkedin">
                                        <i className="fa fa-linkedin"></i>
                                    </a>
                                </li>
                                </ul>
                                {/* <!-- /.menu-footer --> */}

                            </div>
                        </div>
                    </div>
                </div>
                /* <!-- /.footer-bottom --> */
        );
    }
}

export default BottomBar;