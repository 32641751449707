////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////

// import React, { Component } from "react";
// import { Header, TopBar, Footer, Loader } from "../layouts/general";

// class Resources extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       headers: [
//         {
//           id: 1,
//           names: "Pages",
//         },
//       ],
//       dataBanner: [
//         {
//           id: 1,
//           classename01: "flat-title-page parallax parallax7 style3",
//           classename02: "container wrap-title-page ",
//           links: "/about-us",
//           names: "About Us",
//         },
//       ],
//       selectedResource: null,
//     };

//     this.resourceData = [
//       {
//         category: 'Information for Immigration',
//         items: [
//           { name: 'National-Migration-Policy', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe1%2FNational-Migration-Policy.pdf?alt=media&token=4d5adb3c-c8dc-431d-b282-37254ab938c2' },
//           { name: 'Non Ciitizen (Registration, Immigration and Explusion)1965', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe1%2FNon%20Ciitizen%20(Registration%2C%20Immigration%20and%20Explusion)1965.pdf?alt=media&token=23608649-9fb3-43a3-b83e-781e25b3ecc6' },
//           { name: 'The Citizenship (Amendt) Act, 2017', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe1%2FThe%20Citizenship%20(Amendt)%20Act%2C%202017.pdf?alt=media&token=5891cac1-0b86-4e71-a513-fb5ac284d2a7' },
//           { name: 'The Citizenship Act 1973', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe1%2FThe%20Citizenship%20Act%201973.pdf?alt=media&token=3faad6c5-9294-4eb2-a4b2-09dc1a7a3a65' },
//           { name: 'The Citizenship Act 2006', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe1%2FThe%20Citizenship%20Act%202006.pdf?alt=media&token=1ee9ffb2-b60d-4e3e-80c6-8f06d5bf9a9f' },
//         ],
//       },
//       {
//         category: 'Environmental Protection Act',
//         items: [
//           { name: 'EPA Act 2008', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe2%2FEPA%20Act%202008.pdf?alt=media&token=b651f51f-65e9-4c3b-a173-16991fd4fae8' },
//           { name: 'THE ENVIRONMENT PROTECTION AGENCY ACT, 2022', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe2%2FTHE%20ENVIRONMENT%20PROTECTION%20AGENCY%20ACT%2C%202022.pdf?alt=media&token=9df0a5ff-3f68-4a59-a6ca-e87cb4ef793f' },
//         ],
//       },
//       {
//         category: 'Employment Act',
//         items: [
//           { name: 'Employment Act 2023 (4)', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe3%2FEmployment%20Act%202023%20(4).pdf?alt=media&token=aef99d89-c994-495d-b61c-a09e752a0486' },
//         ],
//       },
//       // Add more categories and items as needed
//       {
//         category: 'NASSIT ACT',
//         items: [
//           { name: 'NASSIT ACT 2001', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe4%2FNASSIT%20ACT%202001.pdf?alt=media&token=3fa4b666-e72d-4708-9815-bad560e89a29' },
//           { name: 'The National Social Security and Insurance Trust (Amdt.) . Act, 2016', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe4%2FThe%20National%20Social%20Security%20and%20Insurance%20Trust%20(Amdt.)%20.%20Act%2C%202016..pdf?alt=media&token=eff49f40-bc1e-432d-acd0-c0a11e5e3d4e' },
//         ],
//       },
//       {
//         category: 'Information for Energy',
//         items: [
//           { name: 'Energy-Efficiency-Policy-2nd-signed', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FEnergy-Efficiency-Policy-2nd-signed.pdf?alt=media&token=5627d953-6838-4649-8c65-a9a220b2ce49' },
//           { name: 'NATIONAL-ENERGY-POLICY', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FNATIONAL-ENERGY-POLICY.pdf?alt=media&token=0fa5a6c6-9bf8-4562-969e-c26ee4fce30f' },
//           { name: 'National Electricity (Amendment) Act 2018', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FNational%20Electricity%20(Amendment)%20Act%202018.pdf?alt=media&token=a20c70c1-a89e-4f37-ba7f-8070055194f1' },
//           { name: 'Renewable_Energy_Policy', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FRenewable_Energy_Policy.pdf?alt=media&token=ab713715-08d1-4856-8692-978eb63044ab' },
//           { name: 'THE NATIONAL ELECTRICITY ACT (1)', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FTHE%20NATIONAL%20ELECTRICITY%20ACT%20(1).pdf?alt=media&token=3270f4a0-ccdf-4cec-97be-76d2bb14a07a' },
//           { name: 'THE NATIONAL ELECTRICITY ACT', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FTHE%20NATIONAL%20ELECTRICITY%20ACT.pdf?alt=media&token=4ecc1198-fafe-4ba6-9968-1dfca5080d31' },
//           { name: 'The Electricity Act, 2017', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FThe%20Electricity%20Act%2C%202017.pdf?alt=media&token=046a7c4e-4dc9-42fc-8ee7-2aa5e7423c27' },
//         ],
//       },
//       {
//         category: 'Information for Payroll Tax',
//         items: [
//           { name: 'Payroll-Tax-Act-1974', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe6%2FPayroll-Tax-Act-1974.pdf?alt=media&token=0ba963d5-a450-4b00-bcc5-fe1cb17e81fa' },
//           { name: 'The Imposition of Pay-Roll Tax - 2016', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe6%2FThe%20Imposition%20of%20Pay-Roll%20Tax%20-%202016.pdf?alt=media&token=9161ee82-d8bf-4ea4-af3c-fa95b3e3e6f1' },
//         ],
//       },
//       {
//         category: 'National Mineral Agency',
//         items: [
//           { name: 'The-National-Minerals-Agency-Act-2012', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe7%2FThe-National-Minerals-Agency-Act-2012.pdf?alt=media&token=03f32af0-dd0d-4d1f-8b20-b3be7ce3b1be' },
//         ],
//       },
//       {
//         category: 'Finance Act',
//         items: [
//           { name: 'Consolidated-Income-Tax-Act as amended 2009', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FConsolidated-Income-Tax-Act%20as%20amended%202009.pdf?alt=media&token=6f8636f8-6bcc-4d48-a791-931e7bac9033' },
//           { name: 'Finance-Act-2010', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FFinance-Act-2010.pdf?alt=media&token=f90ce186-11cc-4cd0-a3ee-ee2f2e6a5a3c' },
//           { name: 'Finance-Act-2011', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FFinance-Act-2011.pdf?alt=media&token=8493931c-7dac-4c05-8772-1ff69aa19499' },
//           { name: 'Income Tax Act 2000 as amended (Revised to FA 2015)', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FIncome%20Tax%20Act%202000%20as%20amended%20(Revised%20to%20FA%202015).pdf?alt=media&token=f831a33a-50b9-4012-b389-29eac8838eca' },
//           { name: 'THE FINANCE (AMENDMENT) ACT, 2018', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FTHE%20FINANCE%20(AMENDMENT)%20ACT%2C%202018.pdf?alt=media&token=dda563cb-3a58-4d4c-b403-f030e452ab45' },
//           { name: 'The Finance Act 2024 (4)', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe%20Finance%20Act%202024%20(4).pdf?alt=media&token=658bfa36-b871-41ec-926a-a1feed2483ab' },
//           { name: 'The Finance Act, 2016', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe%20Finance%20Act%2C%202016.pdf?alt=media&token=97df5ec3-e3ca-4bce-913c-173d3d64e64e' },
//           { name: 'The Finance Act, 2017', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe%20Finance%20Act%2C%202017.pdf?alt=media&token=257fe3b5-2624-46fb-9bc1-9278463aad3d' },
//           { name: 'The-Finance-Act-2013', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe-Finance-Act-2013.pdf?alt=media&token=aafda6f9-77d9-436a-8741-39d4ae884fa5' },
//           { name: 'The-Finance-Act-2019', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe-Finance-Act-2019.pdf?alt=media&token=a1d637cb-ff6b-41eb-b206-792226e11a8c' },
//           { name: 'The-Finance-Act-2020', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe-Finance-Act-2020.pdf?alt=media&token=674110dd-5cc9-41cb-b4c2-f8711d76b610' },
//           { name: 'The-Finance-Acts-2021-1 (1)', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe-Finance-Acts-2021-1%20(1).pdf?alt=media&token=b074d6cd-a495-40d1-b573-645311e46416' },
//           { name: 'The-Finance-Acts-2021-1', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe-Finance-Acts-2021-1.pdf?alt=media&token=3ad45ab8-eeb8-4c35-aa64-440894d0fb94' },
//         ],
//       },
//       {
//         category: 'Mines and Mineral Development',
//         items: [
//           { name: 'Mines and Minerals Act 2009', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe9%2FMines%20and%20Minerals%20Act%202009.pdf?alt=media&token=71e892cd-2d24-42b7-a1a5-32696382368e' },
//           { name: 'Mines and Minerals Development Act 2022', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe9%2FMines%20and%20Minerals%20Development%20Act%202022.pdf?alt=media&token=dfd9c1c0-d7c3-48d1-8c68-b31f0ceb299c' },
//           { name: 'Sierra Leone Mines and Minerals Development and Management Corporation Act.', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe9%2FSierra%20Leone%20Mines%20and%20Minerals%20Development%20and%20Management%20Corporation%20Act.pdf?alt=media&token=d4669735-27ed-4364-8f02-47beee36b8bd' },
//         ],
//       },
//       {
//         category: 'Collective Bargaining Agreements',
//         items: [
//           { name: 'CBA - BUILDING & CONSTRUCTION ', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe10%2FCBA%20-%20BUILDING%20%26%20CONSTRUCTION%20.pdf?alt=media&token=7a807bed-d19b-449e-a53b-905e5c023f48' },
//           { name: 'CBA - HOTEL CATERING  AND ENTERTAINMENT (2) (2)', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe10%2FCBA%20-%20HOTEL%20CATERING%20%20AND%20ENTERTAINMENT%20(2)%20(2).pdf?alt=media&token=119ffbbe-299b-48b3-9711-3ef9f814d1a7' },
//           { name: 'CBA - Mining Sector 2016', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe10%2FCBA%20-%20Mining%20Sector%202016.pdf?alt=media&token=0109fb2c-baab-4460-9fab-e263757ac3b5' },
//           { name: 'CBA - Services Sector 2020', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe10%2FCBA%20-%20Services%20Sector%202020.pdf?alt=media&token=bd0f281a-f58a-4f70-8bf5-567fdd90fe0f' },
//           { name: 'CBA - Shipping, Forward and clearing , clerical, etc', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe10%2FCBA%20-%20Shipping%2C%20Forward%20and%20clearing%20%2C%20clerical%2C%20etc.pdf?alt=media&token=7094f050-e4a8-482c-a28b-9731262fdfb6' },
//         ],
//       },
//     ];
//   }

//   render() {
//     const { selectedResource } = this.state;
//     return (
//       <div>
//         <Loader />
//         <TopBar />
//         {this.state.headers.map((data) => (
//           <Header data={data} key={data.id} />
//         ))}

//         <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
//           <div className="container mx-auto px-4">
//             <h2 className="text-3xl font-bold mb-8 text-center">Resources</h2>
//             <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//               {this.resourceData.map((resource, index) => (
//                 <div key={index} className="bg-white p-4 rounded-lg shadow-md">
//                   <button
//                     className="w-full font-medium text-lg"
//                     style={{
//                       color: selectedResource === index ? 'black' : 'black',
//                       border: `1px solid ${selectedResource === index ? 'gray' : 'gray'}`,
//                       backgroundColor: `${selectedResource === index ? 'gray' : 'white'}`,
//                       padding: '12px',
//                       borderRadius: '8px',
//                       marginBottom: '8px',
//                       textAlign: 'center', // Center text within the button
//                       lineHeight: '1.5', // Set the line height for vertical centering
//                       transition: 'background-color 0.3s',
//                     }}
//                     onClick={() => this.setState({ selectedResource: index })}
//                   >
//                     {resource.category}
//                   </button>
//                   {selectedResource === index && (
//                     <div className="mt-2">
//                       {resource.items.map((item, itemIndex) => (
//                         <div key={itemIndex} className="mb-2 text-gray-700">
//                           <a
//                             href={item.link}
//                             target="_blank"
//                             rel="noopener noreferrer"
//                             className="flex items-center"
//                           >
//                             <span
//                               className="w-4 h-4 mr-2 bg-gray-300 rounded-full"
//                             ></span>
//                             {item.name}
//                           </a>
//                         </div>
//                       ))}
//                     </div>
//                   )}
//                 </div>
//               ))}
//             </div>
//           </div>
//         </section>
//         <Footer />
//       </div>
//     );
//   }
// }

// export default Resources;


///////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Header, TopBar, Footer, Loader } from "../layouts/general";

class Resources extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headers: [
        {
          id: 1,
          names: "Pages",
        },
      ],
      dataBanner: [
        {
          id: 1,
          classename01: "flat-title-page parallax parallax7 style3",
          classename02: "container wrap-title-page ",
          links: "/about-us",
          names: "About Us",
        },
      ],
      selectedResource: null,
    };

    this.resourceData = [
      {
        category: 'Information for Immigration',
        items: [
          { name: 'National-Migration-Policy', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe1%2FNational-Migration-Policy.pdf?alt=media&token=4d5adb3c-c8dc-431d-b282-37254ab938c2' },
          { name: 'The Citizenship (Amendt) Act, 2017', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe1%2FThe%20Citizenship%20(Amendt)%20Act%2C%202017.pdf?alt=media&token=5891cac1-0b86-4e71-a513-fb5ac284d2a7' },
          { name: 'The Citizenship Act 2006', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe1%2FThe%20Citizenship%20Act%202006.pdf?alt=media&token=1ee9ffb2-b60d-4e3e-80c6-8f06d5bf9a9f' },
          { name: 'The Citizenship Act 1973', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe1%2FThe%20Citizenship%20Act%201973.pdf?alt=media&token=3faad6c5-9294-4eb2-a4b2-09dc1a7a3a65' },
          { name: 'Non Ciitizen (Registration, Immigration and Explusion)1965', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe1%2FNon%20Ciitizen%20(Registration%2C%20Immigration%20and%20Explusion)1965.pdf?alt=media&token=23608649-9fb3-43a3-b83e-781e25b3ecc6' },
        ],
      },
      {
        category: 'Environmental Protection Act',
        items: [
          { name: 'THE ENVIRONMENT PROTECTION AGENCY ACT, 2022', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe2%2FTHE%20ENVIRONMENT%20PROTECTION%20AGENCY%20ACT%2C%202022.pdf?alt=media&token=9df0a5ff-3f68-4a59-a6ca-e87cb4ef793f' },
          { name: 'EPA Act 2008', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe2%2FEPA%20Act%202008.pdf?alt=media&token=b651f51f-65e9-4c3b-a173-16991fd4fae8' },
        ],
      },
      {
        category: 'Employment Act',
        items: [
          { name: 'Employment Act 2023 (4)', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe3%2FEmployment%20Act%202023%20(4).pdf?alt=media&token=aef99d89-c994-495d-b61c-a09e752a0486' },
        ],
      },
      // Add more categories and items as needed
      {
        category: 'NASSIT ACT',
        items: [
          { name: 'The National Social Security and Insurance Trust (Amdt.) . Act, 2016', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe4%2FThe%20National%20Social%20Security%20and%20Insurance%20Trust%20(Amdt.)%20.%20Act%2C%202016..pdf?alt=media&token=eff49f40-bc1e-432d-acd0-c0a11e5e3d4e' },
          { name: 'NASSIT ACT 2001', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe4%2FNASSIT%20ACT%202001.pdf?alt=media&token=3fa4b666-e72d-4708-9815-bad560e89a29' },
        ],
      },
      {
        category: 'Information for Energy',
        items: [
          { name: 'National Electricity (Amendment) Act 2018', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FNational%20Electricity%20(Amendment)%20Act%202018.pdf?alt=media&token=a20c70c1-a89e-4f37-ba7f-8070055194f1' },
          { name: 'The Electricity Act, 2017', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FThe%20Electricity%20Act%2C%202017.pdf?alt=media&token=046a7c4e-4dc9-42fc-8ee7-2aa5e7423c27' },
          { name: 'Energy-Efficiency-Policy-2nd-signed', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FEnergy-Efficiency-Policy-2nd-signed.pdf?alt=media&token=5627d953-6838-4649-8c65-a9a220b2ce49' },
          { name: 'Renewable_Energy_Policy', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FRenewable_Energy_Policy.pdf?alt=media&token=ab713715-08d1-4856-8692-978eb63044ab' },
          { name: 'THE NATIONAL ELECTRICITY ACT (1)', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FTHE%20NATIONAL%20ELECTRICITY%20ACT%20(1).pdf?alt=media&token=3270f4a0-ccdf-4cec-97be-76d2bb14a07a' },
          { name: 'THE NATIONAL ELECTRICITY ACT', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FTHE%20NATIONAL%20ELECTRICITY%20ACT.pdf?alt=media&token=4ecc1198-fafe-4ba6-9968-1dfca5080d31' },
          { name: 'NATIONAL-ENERGY-POLICY', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe5%2FNATIONAL-ENERGY-POLICY.pdf?alt=media&token=0fa5a6c6-9bf8-4562-969e-c26ee4fce30f' },
        ],
      },
      {
        category: 'Information for Payroll Tax',
        items: [
          { name: 'The Imposition of Pay-Roll Tax - 2016', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe6%2FThe%20Imposition%20of%20Pay-Roll%20Tax%20-%202016.pdf?alt=media&token=9161ee82-d8bf-4ea4-af3c-fa95b3e3e6f1' },
          { name: 'Payroll-Tax-Act-1974', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe6%2FPayroll-Tax-Act-1974.pdf?alt=media&token=0ba963d5-a450-4b00-bcc5-fe1cb17e81fa' },
        ],
      },
      {
        category: 'National Mineral Agency',
        items: [
          { name: 'The-National-Minerals-Agency-Act-2012', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe7%2FThe-National-Minerals-Agency-Act-2012.pdf?alt=media&token=03f32af0-dd0d-4d1f-8b20-b3be7ce3b1be' },
        ],
      },
      {
        category: 'Finance Act',
        items: [
          { name: 'The Finance Act 2024 (4)', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe%20Finance%20Act%202024%20(4).pdf?alt=media&token=658bfa36-b871-41ec-926a-a1feed2483ab' },
          { name: 'The-Finance-Acts-2021-1', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe-Finance-Acts-2021-1.pdf?alt=media&token=3ad45ab8-eeb8-4c35-aa64-440894d0fb94' },
          { name: 'The-Finance-Acts-2021-1 (1)', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe-Finance-Acts-2021-1%20(1).pdf?alt=media&token=b074d6cd-a495-40d1-b573-645311e46416' },
          { name: 'The-Finance-Act-2020', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe-Finance-Act-2020.pdf?alt=media&token=674110dd-5cc9-41cb-b4c2-f8711d76b610' },
          { name: 'The-Finance-Act-2019', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe-Finance-Act-2019.pdf?alt=media&token=a1d637cb-ff6b-41eb-b206-792226e11a8c' },
          { name: 'THE FINANCE (AMENDMENT) ACT, 2018', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FTHE%20FINANCE%20(AMENDMENT)%20ACT%2C%202018.pdf?alt=media&token=dda563cb-3a58-4d4c-b403-f030e452ab45' },
          { name: 'The Finance Act, 2017', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe%20Finance%20Act%2C%202017.pdf?alt=media&token=257fe3b5-2624-46fb-9bc1-9278463aad3d' },
          { name: 'The Finance Act, 2016', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe%20Finance%20Act%2C%202016.pdf?alt=media&token=97df5ec3-e3ca-4bce-913c-173d3d64e64e' },
          { name: 'Income Tax Act 2000 as amended (Revised to FA 2015)', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FIncome%20Tax%20Act%202000%20as%20amended%20(Revised%20to%20FA%202015).pdf?alt=media&token=f831a33a-50b9-4012-b389-29eac8838eca' },
          { name: 'The-Finance-Act-2013', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FThe-Finance-Act-2013.pdf?alt=media&token=aafda6f9-77d9-436a-8741-39d4ae884fa5' },
          { name: 'Finance-Act-2011', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FFinance-Act-2011.pdf?alt=media&token=8493931c-7dac-4c05-8772-1ff69aa19499' },
          { name: 'Finance-Act-2010', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FFinance-Act-2010.pdf?alt=media&token=f90ce186-11cc-4cd0-a3ee-ee2f2e6a5a3c' },
          { name: 'Consolidated-Income-Tax-Act as amended 2009', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe8%2FConsolidated-Income-Tax-Act%20as%20amended%202009.pdf?alt=media&token=6f8636f8-6bcc-4d48-a791-931e7bac9033' },
        ],
      },
      {
        category: 'Mines and Mineral Development',
        items: [
          { name: 'Sierra Leone Mines and Minerals Development and Management Corporation Act.', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe9%2FSierra%20Leone%20Mines%20and%20Minerals%20Development%20and%20Management%20Corporation%20Act.pdf?alt=media&token=f2b450ae-b315-405c-9efc-d4deba5f874c' },
          { name: 'Mines and Minerals Development Act 2022', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe9%2FMines%20and%20Minerals%20Development%20Act%202022.pdf?alt=media&token=ee9e7e50-5d20-423f-907f-157c9cdb88b9' },
          { name: 'Mines and Minerals Act 2009', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe9%2FMines%20and%20Minerals%20Act%202009.pdf?alt=media&token=2ba2568c-f0fc-43c6-8cda-c264db9dced1' },
        ],
      },
      {
        category: 'Collective Bargaining Agreements',
        items: [
          { name: 'CBA - Shipping, Forward and clearing , clerical, etc', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe10%2FCBA%20-%20Shipping%2C%20Forward%20and%20clearing%20%2C%20clerical%2C%20etc.pdf?alt=media&token=863a1028-ed23-4755-be0b-cd6fe915bf2f' },
          { name: 'CBA - Services Sector 2020', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe10%2FCBA%20-%20Services%20Sector%202020.pdf?alt=media&token=d7431574-b5b2-4696-8971-5c6d9867816c' },
          { name: 'CBA - Mining Sector 2016', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe10%2FCBA%20-%20Mining%20Sector%202016.pdf?alt=media&token=8693eadc-12d9-4117-83e9-3aba143f9ce2' },
          { name: 'CBA - HOTEL CATERING  AND ENTERTAINMENT (2) (2)', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe10%2FCBA%20-%20HOTEL%20CATERING%20%20AND%20ENTERTAINMENT%20(2)%20(2).pdf?alt=media&token=54300ec5-2eee-4df1-8626-23614fb28257' },
          { name: 'CBA - BUILDING & CONSTRUCTION ', link: 'https://firebasestorage.googleapis.com/v0/b/gordonassociates-e04ed.appspot.com/o/resources%2Fe10%2FCBA%20-%20BUILDING%20%26%20CONSTRUCTION%20.pdf?alt=media&token=f84c2355-8071-49d9-97c1-b302cf1ae5a7' },
        ],
      },
    ];
  }

  render() {
    const { selectedResource } = this.state;
    return (
      <div>
        <Loader />
        <TopBar />
        {this.state.headers.map((data) => (
          <Header data={data} key={data.id} />
        ))}

        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container mx-auto px-4">
            {/* <h2 className="text-3xl font-bold mb-8 text-center">Resources</h2> */}
            <h2 className="text-4xl font-bold mb-4 text-center">A collection of documents and policies for your reference</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {this.resourceData.map((resource, index) => (
                <div key={index} className="bg-white p-4 rounded-lg shadow-md">
                  <button
                    className="w-full font-medium text-lg"
                    style={{
                      color: selectedResource === index ? 'black' : 'black',
                      border: `1px solid ${selectedResource === index ? 'gray' : 'gray'}`,
                      backgroundColor: `${selectedResource === index ? 'gray' : 'white'}`,
                      padding: '12px',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      textAlign: 'center', // Center text within the button
                      lineHeight: '1.5', // Set the line height for vertical centering
                      transition: 'background-color 0.3s',
                    }}
                    onClick={() => this.setState({ selectedResource: index })}
                  >
                    {resource.category}
                  </button>
                  {selectedResource === index && (
                    <div className="mt-2">
                      {resource.items.map((item, itemIndex) => (
                        <div key={itemIndex} className="mb-2 text-gray-700">
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center"
                          >
                            <span
                              className="w-4 h-4 mr-2 bg-gray-300 rounded-full"
                            ></span>
                            {item.name}
                          </a>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Resources;


















