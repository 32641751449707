// import React, { Component } from "react";
// class BBoxView extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       imgslide: [
//         {
//           id: "1",
//           srcimg: "images/about/s01.jpg",
//         },
//         {
//           id: "2",
//           srcimg: "images/about/s01.jpg",
//         },
//       ],
//       content: [
//         {
//           id: "1",
//           title: "BUSINESS ADVISORY",
//           description:
//             "Gordon & Associates works closely with clients to understand their unique challenges and develop tailored strategies for growth, optimization, and overall business success.",
//         },
//       ],
//       blogtimeline: [
//         {
//           id: "1",
//           year: "2012",
//           title: "Market Analysis",
//           description: `We help businesses minimize risks, optimize their resources, and seize growth opportunities. We work
//             closely with clients to customize their approach and recommendations based on the industry, business
//             goals, and market dynamics. By leveraging data-driven insights and expert knowledge, businesses can
//             make well-informed decisions that lead to successful market entry, expansion, and sustained profitability.`,
//         },
//         {
//           id: "2",
//           year: "2013",
//           title: "Research and Innovation",
//           description: `Gordon &amp; Associates has a specialist Research and Innovation team. On the research side, we are a
//           trusted research implementation and evaluation partner. On the innovation side, we are customer/user-led
//           and sustainability obsessed. We can help you define your ambition, refocus your innovation pipeline, and
//           adopt an approach that achieves scale, impact, and return on investment. With over 10+ years’ experience
//           across the innovation spectrum, we partner with you to translate innovation into breakthrough results and
//           real-world impact. Our Research and Innovation consulting helps you stay two steps ahead of the
//           disruption with an innovation strategy and operating model aligned with your goals and design to deliver
//           high-impact results and sustainable solutions.`,
//         },
//         {
//           id: "3",
//           year: "2014",
//           title: "HR Advisory",
//           description: `Gordon & Associates extends a comprehensive range of HR strategic guidance and support, assisting
//             organizations seeking to optimize their human resources and create a positive and productive work
//             environment. Our HR advisory team collaborates with businesses to address HR challenges, enhance
//             processes, and align strategies with overarching business objectives.`,
//         },
//         {
//           id: "4",
//           year: "2014",
//           title: "Environmental, Social, and Governance (ESG) Advisory",
//           description: `Gordon &amp; Associates specializes in ESG Advisory services to businesses, helping businesses develop and
//           execute strategies to proficiently manage their ESG impacts, risks, and opportunities. We assist in
//           integrating sustainability practices, ethics, and responsible governance into daily operations. Our services
//           include evaluating and reporting on sustainability initiatives.
//           \n\n
//           For Environment, we focus on sustainability, resource conservation, and environmental risks.
//           \n
//           In the Social realm, we address community engagement, diversity, inclusion, and employee well-being.
//           \n
//           In the area of Governance, we enhance corporate governance practices, ensuring transparency, accountability, and ethical decision-making for our clients.`,
//         },
//         {
//           id: "5",
//           year: "2014",
//           title: "Accounting and Bookkeeping",
//           description: `We provide expert guidance and assistance to businesses and organizations in managing their financial
//           records, transactions, and reporting. Our suite of services is designed to help businesses maintain accurate
//           and organized financial records, comply with financial regulations, make informed decisions, and optimize
//           their financial processes.`,
//         },
//         {
//           id: "6",
//           year: "2014",
//           title: "Government Affairs and Advocacy",
//           description: `At Gordon & Associates, we offer strategic counsel and assistance to businesses in effectively managing
//           their relationship with government bodies, regulatory agencies and stakeholders. Our expertise lies in
//           aiding clients understand and navigate the regulatory landscape, build positive relationships with
//           policymakers, and effectively advocate for their interests. Through collaborative efforts, we tailor strategies
//           to clients’ specific needs and goals, contributing to business success and sustainability within a complex
//           and ever-evolving environment.`,
//         },
//       ],
//     };
//   }
//   render() {
//     return (
//       <div>
//         {/* <div className="flexslider s2">
//           <div className="flat-slides">
//             <ul className="slides">
//               {this.state.imgslide.map((data) => (
//                 <li key={data.id}>
//                   <img src={data.srcimg} alt="img" />
//                 </li>
//               ))}
//             </ul>
//           </div>
//         </div> */}
//         {this.state.content.map((data) => (
//           <div className="box-content" key={data.id}>
//             <div className="title">{data.title}</div>
//             <p>{data.description}</p>
//             <div className="dividers dividers-bc-v4"></div>
//           </div>
//         ))}

//         {this.state.blogtimeline.map((data) => (
//           <div className="flat-text-block-timeline" key={data.id}>
//             {/* <div className="year">{data.year}</div> */}
//             <div className="flat-timeline-content">
//               <div className="box-content">
//                 <div className="title">{data.title}</div>
//                 <p>{data.description}</p>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     );
//   }
// }

// export default BBoxView;
///////////////////////////////////////////////////////////////////
import React, { Component } from "react";
class RBoxView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgslide: [
        {
          id: "1",
          srcimg: "images/about/s01.jpg",
        },
        {
          id: "2",
          srcimg: "images/about/s01.jpg",
        },
      ],
      content: [
        {
          id: "1",
          title: "RISK ADVISORY",
          description: `We provide insightful guidance and support, aiding businesses and organizations in identifying, assessing,
          managing, and mitigating various types of risks that could impact their operations, reputation, financial
          stability, and overall success. Our goal is to help clients make informed decisions that balance risk and
          reward, enhance risk management practices, and create strategies to navigate uncertainties effectively.
            \n
        The risk consulting services provided at Gordon & Associates include:`,
        },
      ],
      blogtimeline: [
        {
          id: "1",
          year: "2012",
          title: "Risk Assessment and Management",
          description: `Gordon & Associates offers Risk Assessment and Management services to clients, providing a
          comprehensive evaluation of potential risks and the development of effective risk management strategies.`,
        },
        {
          id: "2",
          year: "2013",
          title: "Compliance Risk Services",
          description: `We specialize in Legal and Regulatory Risk Services, expertly navigating and managing risks linked to
          legal and regulatory changes. Our focus is on ensuring strict adherence to regulatory requirements and
          preventing legal consequences.`,
        },
        {
          id: "3",
          year: "2014",
          title: "Corporate Due Diligence",
          description: `Gordon & Associates offers a comprehensive suite of Corporate Due Diligence services that encompass
          financial, legal, operational, compliance, intellectual property, HR, market, and tax evaluations. Our service
          is instrumental in equipping clients with the information they need to make informed decisions and adeptly
          manage risks during critical transactions.`,
        },
        {
          id: "4",
          year: "2014",
          title: "Internal Audit, Fraud Detection, and Prevention Services",
          description: `Gordon & Associates excel in providing Internal Audit services tailored to help clients assess and enhance
          their internal control systems, risk management, and governance. Additionally, we specialize in Fraud
          Detection and Prevention, offering strategies to identify and prevent fraudulent activities within
          organizations. These services are instrumental in safeguarding against financial losses, preserving
          reputation, and mitigating legal consequences.`,
        },
        {
          id: "5",
          year: "2014",
          title: "Project Risk Management",
          description: `We provide Project Risk Management services to clients, offering a comprehensive range of services to
          effectively manage and mitigate risks associated with their projects and ensure successful project delivery.`,
        },
      ],
    };
  }

  renderDescription(description) {
    const paragraphs = description.split("\n\n");
    return paragraphs.map((paragraph, index) => <p key={index}>{paragraph}</p>);
  }

  render() {
    return (
      <div>
        {this.state.content.map((data) => (
          <div className="box-content" key={data.id}>
            <div className="title">{data.title}</div>
            <p>{data.description}</p>
            <div className="dividers dividers-bc-v4"></div>
          </div>
        ))}

        {this.state.blogtimeline.map((data, index) => (
          <div
            className="flat-text-block-timeline"
            key={data.id}
            style={{
              display: "flex",
              flexDirection: index % 2 === 0 ? "row" : "row-reverse",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "50px",
            }}
          >
            <div
              className="flat-timeline-content"
              style={{
                flexBasis: "100%",
                //textAlign: "center", // Center the text on mobile devices
              }}
            >
              <div className="box-content">
                <div className="title">{data.title}</div>
                {this.renderDescription(data.description)}
              </div>
            </div>

            <div
              className="flat-timeline-img"
              style={{
                flexBasis: "45%",
              }}
              ref={(el) => {
                if (el && window.innerWidth >= 768) {
                  el.style.display = "block";
                } else {
                  el.style.display = "none";
                }
              }}
            >
              <img
                src={`images/services/risk/${data.id}.png`}
                alt="img"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        ))}

      </div>
    );
  }
}

export default RBoxView;

//Josepg
